import { useEffect } from "react";
import { Icon, Img } from "../../../contents/content";
import { CircularProgress, Image, Stack, VStack } from "@chakra-ui/react";
import { ColorRing } from 'react-loader-spinner'

const Loader = ({ isLoading }) => {

    useEffect(() => {

    },[]);
    
    return(
        <div style={{height:'100vh', width:'100vw', backgroundColor:'black', margin:'auto', justifyContent:'center', display:'flex'}}>
            <VStack margin={'auto'} display={'flex'} align={'center'} alignItems={'center'}>
                <Image height={'100px'} width={'200px'} src={Img.logo} mx={'auto'}/>
                {/* <CircularProgress mx={'auto'} value={40}></CircularProgress> */}
                <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                />
            </VStack>
        </div>
    )
};

export default Loader;