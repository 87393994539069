import { Box, Button, Image, Progress, Stack, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useRouter } from "../../../../../hooks/use-router";
import { DashboardData, Icon, Img } from "../../../../../contents/content";
import { FaEllipsis, FaPenToSquare } from "react-icons/fa6";

const ResumeCourse = ({ course, userStatus }) => {

  const router = useRouter();

  const [progress, setProgress] = useState(0);

    const calculateProgress = () => {
      if (userStatus !== undefined && course !== undefined) {
        const overallDuration = course.duration ?? 0;
        const userCompletedDuration = userStatus.current_watch_timestamp ?? 0;
        const math = (userCompletedDuration / overallDuration) * 100;
        if (progress !== math) {
          setProgress(math);
        };
      };
    };

    const navigateToEducation = () => {
      router.push(`/education/${course.id}`);
    };

    useEffect(() => {
      calculateProgress();
    });
    
    return(
        <Box
            bg="rgba(24, 24, 24, 1)"
            h="auto"
            w="380px"
            boxShadow="0px 0px 16px 0px rgba(24, 28, 50, 0.01)"
            borderRadius="14px"
          >
            <Box p="24px">
              <Text
                fontFamily="'Poppins', sans-serif"
                fontSize="17px"
                fontWeight="600"
                lineHeight="21px"
                textAlign="left"
                color="rgba(255, 255, 255, 1)"
              >
                Start your mentorship
              </Text>

              <Box borderRadius="12px" w="full" mt="20px" position="relative">
                <Image
                  borderRadius={'12px'}
                  src={course.thumbnail}
                  alt="thumb"
                  w="full"
                  h="214px"
                  // minH={'184px'}
                  fit={'cover'}
                />
                <Stack
                  position="absolute"
                  top="0"
                  left="0"
                  w="full"
                  height="full"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                  onClick={() => navigateToEducation()}
                  bg="rgba(0, 0, 0, 0.6)"
                    w="89.31px"
                    h="58.2px"
                    boxShadow="0px 0px 20px 0px rgba(0, 0, 0, 0.3)"
                    borderRadius="12px"
                  >
                    <Image src={Icon.play} alt="play" />
                  </Button>
                </Stack>
              </Box>

              <Box pt="15px">
                <Text
                  fontFamily="'Poppins', sans-serif"
                  fontSize="20px"
                  fontWeight="600"
                  lineHeight="21px"
                  textAlign="left"
                  noOfLines={2}
                  py={'8px'}
                  color="rgba(255, 255, 255, 1)"
                >
                  {course.title}
                </Text>
                <Text
                  fontFamily="'Poppins', sans-serif"
                  fontSize="13px"
                  fontWeight="500"
                  lineHeight="21px"
                  textAlign="left"
                  color="rgba(126, 130, 153, 1)"
                  noOfLines={2}
                >
                  {course.description}
                </Text>
                <Box>
                      <Progress
                      isAnimated
                        value={progress}
                        size="xs"
                        height="8px"
                        colorScheme="red"
                        borderRadius="15px"
                        my={'15px'}
                      />
                    </Box>
                <Button
                  onClick={() => navigateToEducation()}
                  mt="15px"
                  bg="rgba(212, 32, 32, 1)"
                  fontFamily="'Roboto', sans-serif"
                  fontSize="15px"
                  fontWeight="500"
                  color="rgba(255, 255, 255, 1)"
                  _hover={{ bg: "rgb(199 13 13)", transition: "0.3s" }}
                  w="full"
                  borderRadius="9px"
                  height="42px"
                >
                  Resume Lesson
                </Button>
              </Box>
            </Box>
          </Box>
    )
};

export default ResumeCourse;