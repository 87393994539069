import { Box, Button, Image, Stack, Text, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { DashboardData, Icon, Img } from "../../../../../contents/content";
import { FaEllipsis, FaPenToSquare } from "react-icons/fa6";

const Notifications = ({notifications}) => {

    useEffect(() => {

    },[]);
    
    return(
        <Box w="380px" mt="40px" mb="40px">
            <Box
              bg="rgba(255, 255, 255, 1)"
              w="380px"
              height="567px"
              borderRadius="12px"
              p="20px"
              pt="25px"
              pb="65px"
            >
              <Stack flexDir="row" w="full" alignItems="center">
                <Box w="80%">
                  <Text
                    fontFamily="'Poppins', sans-serif"
                    fontSize="17px"
                    fontWeight="600"
                    lineHeight="21px"
                    textAlign="left"
                    color="rgba(63, 66, 84, 1)"
                  >
                    Notifications
                  </Text>
                  <Text
                    fontFamily="'Poppins', sans-serif"
                    fontSize="12px"
                    fontWeight="500"
                    lineHeight="21px"
                    textAlign="left"
                    color="rgba(181, 181, 195, 1)"
                  >
                    See what you've missed
                  </Text>
                </Box>
                <Box textAlign="right" w="20%">
                  <Button
                    bg="rgb(161 165 183 / 19%)"
                    h="35px"
                    p="0"
                    w="25px"
                    borderRadius="10px"
                    color="rgba(161, 165, 183, 1)"
                  >
                    <FaEllipsis />
                  </Button>
                </Box>
              </Stack>

              <Stack w="full" pt="20px" flexDir="row" pb="0">
                <Box w="50px">
                  <Text
                    fontFamily="'Poppins', sans-serif"
                    fontSize="14"
                    fontWeight="600"
                    color="rgba(63, 66, 84, 1)"
                  >
                    08:42
                  </Text>
                </Box>
                <Stack w="25px" alignItems="center" justifyContent="flex-start">
                  <Box
                    w="20px"
                    h="20px"
                    borderRadius="25px"
                    bg="rgba(102, 50, 89, 1)"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    p="0"
                    overflow="hidden"
                    m="0"
                  >
                    <Box
                      w="10px"
                      h="10px"
                      bg="#fff"
                      borderRadius="25px"
                      p="0"
                    ></Box>
                  </Box>
                  <Box
                    bg="rgba(229, 234, 238, 1)"
                    h="40px"
                    w="3px"
                    mt="-2px"
                  ></Box>
                </Stack>
                <Box w="100%" overflow="hidden">
                  <Text
                    fontFamily="'Poppins', sans-serif"
                    fontSize="12px"
                    fontWeight="400"
                    lineHeight="21px"
                    textAlign="left"
                    color="rgba(126, 130, 153, 1)"
                  >
                    Outlines keep you honest. Indulging in poorly driving and
                    keep structure
                  </Text>
                </Box>
              </Stack>

              <Stack
                w="full"
                pt="5px"
                flexDir="row"
                pb="0"
                alignItems="flex-start"
              >
                <Box w="50px">
                  <Text
                    fontFamily="'Poppins', sans-serif"
                    fontSize="14"
                    fontWeight="600"
                    color="rgba(63, 66, 84, 1)"
                  >
                    10:00
                  </Text>
                </Box>
                <Stack w="25px" alignItems="center" justifyContent="flex-start">
                  <Box
                    w="20px"
                    h="20px"
                    borderRadius="25px"
                    bg="rgba(176, 220, 0, 1)"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    p="0"
                    overflow="hidden"
                    m="0"
                  >
                    <Box
                      w="10px"
                      h="10px"
                      bg="#fff"
                      borderRadius="25px"
                      p="0"
                    ></Box>
                  </Box>
                  <Box
                    bg="rgba(229, 234, 238, 1)"
                    h="40px"
                    w="3px"
                    mt="-2px"
                  ></Box>
                </Stack>
                <Box
                  w="100%"
                  overflow="hidden"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mt="-10px"
                  pr="15px"
                >
                  <Text
                    fontFamily="'Poppins', sans-serif"
                    fontSize="13px"
                    fontWeight="600"
                    lineHeight="21px"
                    textAlign="left"
                    color="rgba(63, 66, 84, 1)"
                  >
                    Live Opp Call with
                  </Text>
                  <Box
                    display="flex"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <Image src={Img.avatar1} alt="avatar1" />
                    <Image src={Img.avatar2} alt="avatar2" />
                  </Box>
                </Box>
              </Stack>
              <Stack w="full" pt="5px" flexDir="row" pb="0">
                <Box w="50px">
                  <Text
                    fontFamily="'Poppins', sans-serif"
                    fontSize="14"
                    fontWeight="600"
                    color="rgba(63, 66, 84, 1)"
                  >
                    08:42
                  </Text>
                </Box>
                <Stack w="25px" alignItems="center" justifyContent="flex-start">
                  <Box
                    w="20px"
                    h="20px"
                    borderRadius="25px"
                    bg="rgba(246, 78, 96, 1)"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    p="0"
                    overflow="hidden"
                    m="0"
                  >
                    <Box
                      w="10px"
                      h="10px"
                      bg="#fff"
                      borderRadius="25px"
                      p="0"
                    ></Box>
                  </Box>
                  <Box
                    bg="rgba(229, 234, 238, 1)"
                    h="40px"
                    w="3px"
                    mt="-2px"
                  ></Box>
                </Stack>
                <Box w="100%" overflow="hidden">
                  <Text
                    fontFamily="'Poppins', sans-serif"
                    fontSize="13px"
                    fontWeight="600"
                    lineHeight="21px"
                    textAlign="left"
                    color="rgba(63, 66, 84, 1)"
                    display="flex"
                  >
                    You made a{" "}
                    <Text color="rgba(176, 220, 0, 1)" mx="2px">
                      {" "}
                      $700
                    </Text>{" "}
                    withdrawal
                  </Text>
                </Box>
              </Stack>
              <Stack w="full" pt="5px" flexDir="row" pb="0">
                <Box w="50px">
                  <Text
                    fontFamily="'Poppins', sans-serif"
                    fontSize="14"
                    fontWeight="600"
                    color="rgba(63, 66, 84, 1)"
                  >
                    08:42
                  </Text>
                </Box>
                <Stack w="25px" alignItems="center" justifyContent="flex-start">
                  <Box
                    w="20px"
                    h="20px"
                    borderRadius="25px"
                    bg="rgba(54, 153, 255, 1)"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    p="0"
                    overflow="hidden"
                    m="0"
                  >
                    <Box
                      w="10px"
                      h="10px"
                      bg="#fff"
                      borderRadius="25px"
                      p="0"
                    ></Box>
                  </Box>
                  <Box
                    bg="rgba(229, 234, 238, 1)"
                    h="40px"
                    w="3px"
                    mt="-2px"
                  ></Box>
                </Stack>
                <Box w="100%" overflow="hidden">
                  <Text
                    fontFamily="'Poppins', sans-serif"
                    fontSize="12px"
                    fontWeight="400"
                    lineHeight="21px"
                    textAlign="left"
                    color="rgba(126, 130, 153, 1)"
                  >
                    Outlines keep you honest. Indulging in poorly driving and
                    keep structure keep you honest great
                  </Text>
                </Box>
              </Stack>
              <Stack w="full" pt="5px" flexDir="row" pb="0">
                <Box w="50px">
                  <Text
                    fontFamily="'Poppins', sans-serif"
                    fontSize="14"
                    fontWeight="600"
                    color="rgba(63, 66, 84, 1)"
                  >
                    08:42
                  </Text>
                </Box>
                <Stack w="25px" alignItems="center" justifyContent="flex-start">
                  <Box
                    w="20px"
                    h="20px"
                    borderRadius="25px"
                    bg="rgba(246, 78, 96, 1)"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    p="0"
                    overflow="hidden"
                    m="0"
                  >
                    <Box
                      w="10px"
                      h="10px"
                      bg="#fff"
                      borderRadius="25px"
                      p="0"
                    ></Box>
                  </Box>
                  <Box
                    bg="rgba(229, 234, 238, 1)"
                    h="40px"
                    w="3px"
                    mt="-2px"
                  ></Box>
                </Stack>
                <Box w="100%" overflow="hidden">
                  <Text
                    fontFamily="'Poppins', sans-serif"
                    fontSize="13px"
                    fontWeight="600"
                    lineHeight="21px"
                    textAlign="left"
                    color="rgba(63, 66, 84, 1)"
                    display="flex"
                  >
                    New order placed{" "}
                    <Text color="rgba(176, 220, 0, 1)" mx="2px">
                      {" "}
                      #RRA-2356
                    </Text>
                  </Text>
                </Box>
              </Stack>
              <Stack w="full" pt="5px" flexDir="row" pb="0">
                <Box w="50px">
                  <Text
                    fontFamily="'Poppins', sans-serif"
                    fontSize="14"
                    fontWeight="600"
                    color="rgba(63, 66, 84, 1)"
                  >
                    08:42
                  </Text>
                </Box>
                <Stack w="25px" alignItems="center" justifyContent="flex-start">
                  <Box
                    w="20px"
                    h="20px"
                    borderRadius="25px"
                    bg="rgba(137, 80, 252, 1)"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    p="0"
                    overflow="hidden"
                    m="0"
                  >
                    <Box
                      w="10px"
                      h="10px"
                      bg="#fff"
                      borderRadius="25px"
                      p="0"
                    ></Box>
                  </Box>
                </Stack>
                <Box w="100%" overflow="hidden">
                  <Text
                    fontFamily="'Poppins', sans-serif"
                    fontSize="12px"
                    fontWeight="400"
                    lineHeight="21px"
                    textAlign="left"
                    color="rgba(126, 130, 153, 1)"
                  >
                    Outlines keep you honest. Indulging in poorly driving and
                    keep structure
                  </Text>
                </Box>
              </Stack>
            </Box>
          </Box>
    )
};

export default Notifications;