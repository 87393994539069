import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Image,
  Input,
  Link,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useToast } from "@chakra-ui/react";
import { parseISO, subDays, isBefore } from 'date-fns';
import Footer from "../components/Layouts/Footer";
import { Icon, Img } from "../contents/content";
import { useRouter } from "../hooks/use-router";

const Login = () => {

  const toast = useToast();
  const router = useRouter();
  const [isLoading, setIsloading] = useState(false);

  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');

  const handleChange = (e) => {
    const value = e.target.value;
    if (e.target.id === 'email') {
      setEmailAddress(value);
    } else {
      setPassword(value);
    };
  };

  const tryUserSignIn = async () => {
    if (emailAddress === '' || password === '') {
      return;
    };

    try {
      await fetch(
        "https://corsavailable.herokuapp.com/https://realwealth-api-0787684ad674.herokuapp.com/auth/authenticate", {
        method: "POST",
        headers: {
          "auth": "TEST",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,OPTIONS,PATCH,DELETE,POST,PUT",
          "Access-Control-Allow-Headers":
            "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version",
          "Content-Type": "application/json",
          "Origin": "",
          "X-Requested-With": ""
        },
        body: JSON.stringify({
          email: emailAddress,
          password: password,
        }),
      }
      ).then(async (data) => {
        const json = await data.json();
        if (data.status === 200) {
          const customerDataResult = json["result"];
          if (customerDataResult !== undefined && customerDataResult !== '') {
            console.log(customerDataResult);
            const subscriptionId = customerDataResult.subscription_id ?? '';
            if (subscriptionId !== '') {
              checkUserSubscriptions(customerDataResult, subscriptionId);
            } else {
              showError('Authentication', "We couldn't find your subscription. Please contact customer support");
            };
          }
        } else {
          const error = json['error'];
          if (error !== undefined) {
            showError('Authentication', error);
          } else {
            showError('Authentication', 'Failed to login. Please try again later');
          };
        };
      });
    } catch (error) {
      console.log(error)
    }
    setTimeout(() => {
      setIsloading(false)
    }, 2000);
  };

  const checkUserSubscriptions = (userData, subscriptionId) => {
    const currentDate = Math.floor(new Date().getTime() / 1000);

    fetch(
      "https://corsavailable.herokuapp.com/https://realwealth-api-0787684ad674.herokuapp.com/auth/subscription",
      {
        method: "POST",
        headers: {
          "auth": "TEST",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,OPTIONS,PATCH,DELETE,POST,PUT",
          "Access-Control-Allow-Headers":
            "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version",
          "Content-Type": "application/json",
          "Origin": "",
          "X-Requested-With": ""
        },
        body: JSON.stringify({
          subscriptionId: subscriptionId
        }),
      }
    ).then(async (data) => {
      try {
        const json = await data.json();
        if (data.status === 200) {
          const subscriptionData = json["result"];
          if (subscriptionData !== undefined) {
            const expirationDate = subscriptionData['current_period_end'] ?? 0;
            if (expirationDate >= currentDate) {
              userSubscribed(userData, subscriptionData);
            } else {
              showError('Subscription', "Looks like you don't have access to this platform.");
            };
          } else {
            // Could not find s
            showError('Subscription', "We couldn't find an active subscription. Please check your backoffice.");
          };
        } else {
          const subscriptionError = json["error"];
          showError('Subscription', subscriptionError);
        }
      } catch (error) {
        // console.log(error);
        showError('Subscription', "You don't have access to this platform.");
      };
    })
  };

  const userSubscribed = (userData, subscriptionData) => {
    localStorage.setItem("user", JSON.stringify(userData));
    localStorage.setItem("subscription", JSON.stringify(subscriptionData));
    router.push("/dashboard");
  };

  const showError = (title, description) => {
    toast({
      title: title ?? '',
      description: description ?? '',
      status: 'error',
      duration: 3000,
      isClosable: true,
    })
  };

  useEffect(() => {

  },[]);

  return (
    <div>
      <VStack flexDir="row" overflow="hidden" gap={"0"}>
        <Stack
          w="600px"
          bg="#000001"
          justifyContent="center"
          alignItems="center"
          minH="100vh"
          maxH="100%"
          gap={"26px"}
        >
          <Image src={Img.logo} alt="Img" h="auto" w="250px" />
          <Text
            fontFamily="'Poppins', sans-serif"
            fontSize="22px"
            fontWeight="600"
            textAlign="center"
            color="rgba(255, 255, 255, 1)"
          >
            The Ultimate Wealth<br />
            Building Platform
          </Text>
        </Stack>
        <Stack w="100%">
          <Stack
            w="100%"
            justifyContent="center"
            alignItems="center"
            gap={"0"}
            h="fit-content"
          >
            <Stack w="441px">
              <Text
                fontFamily="'Poppins',sans-serif"
                fontSize="28px"
                fontWeight="600"
                mb={'-4px'}
              >
                Welcome to Real Wealth
              </Text>
              <Text
                fontFamily="'Poppins',sans-serif"
                fontSize="18px"
                fontWeight="600"
                color="rgba(167, 168, 187, 1)"
                mb="25px"
              >
                New Here? <Link color="red">Create an Account</Link>
              </Text>

              <Stack>
                <FormControl>
                  <FormLabel
                  onChange={e => setEmailAddress(e.target.value)}
                    htmlFor="email"
                    fontFamily="'Poppins',sans-serif"
                    fontSize="14px"
                    fontWeight="600"
                  >
                    Email
                  </FormLabel>
                  <Input
                    onChange={handleChange}
                    type="email"
                    id="email"
                    bg="rgba(238, 241, 245, 1)"
                    h="70px"
                    borderRadius="12px"
                    fontFamily="'Poppins',sans-serif"
                    fontSize="14px"
                    fontWeight="600"
                  />
                </FormControl>
              </Stack>

              <Stack my="12px">
                <FormControl>
                  <Box
                    display=" flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={'10px'}
                  >
                    <FormLabel
                      htmlFor="Password"
                      fontFamily="'Poppins',sans-serif"
                      fontSize="14px"
                      fontWeight="600"
                      mb="0"
                    >
                      Password
                    </FormLabel>
                    <Link
                      to=""
                      color="p1"
                      fontFamily="'Poppins',sans-serif"
                      fontSize="14px"
                      fontWeight="600"
                      textColor={'red'}
                    >
                      Forgot Password?
                    </Link>
                  </Box>
                  <Input
                    onChange={handleChange}
                    type="password"
                    id="password"
                    bg="rgba(238, 241, 245, 1)"
                    h="70px"
                    borderRadius="12px"
                    fontFamily="'Poppins',sans-serif"
                    fontSize="14px"
                    fontWeight="600"
                  />
                </FormControl>
              </Stack>
              <Stack w="full" flexDir="row">
                <Button
                  onClick={() => tryUserSignIn()}
                  colorScheme="red"
                  fontFamily="'Poppins',sans-serif"
                  fontSize="15px"
                  fontWeight="600"
                  height={'48px'}
                >
                  Sign In
                </Button>
                <Button
                  bg="rgba(255, 228, 228, 1)"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  fontFamily="'Poppins',sans-serif"
                  fontSize="15px"
                  fontWeight="600"
                  height={'48px'}
                >
                  <Image src={Icon.google}  alt="icon" mx="10px"/> Sign in with Google
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack w="full" h="auto" position="fixed" bottom="0" right="0" left="0">
        {/* <Footer /> */}
        </Stack>
      </VStack>
    </div>
  );
};

export default Login;
