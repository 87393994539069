import {
    Box,
    Button,
    Image,
    Link,
    Stack,
    Text
  } from "@chakra-ui/react";
  import React, { useEffect, useState } from "react";
  import { Icon, Img } from "../../../../../contents/content";

const LessonsHolder = ({ educationData, selectedLesson }) => {

    const loadMoreLessons = (courseId) => {
        console.log('Loading more...');
    };

    const fetchSenderForId = (sender) => {
        const usersObj = localStorage.getItem('users');
        if (usersObj !== undefined && usersObj !== null) {
          const users = JSON.parse(usersObj);
          const filtered = users.filter((user) => user.id === sender);
          if (filtered.length > 0) {
            return filtered[0].name ?? ''
          } else {
            return ''
          };
        } else {
          return ''
        };
      };

      const convertDurationToString = (d) => {
        // d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h + (h == 1 ? " hr " : " hrs ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " m " : " ms ") : "";
        var sDisplay = s > 0 ? s + (s == 1 ? " s" : " s") : "";
        return hDisplay + mDisplay + sDisplay; 
      };

    useEffect(() => {

    },[]);

    return(
        <>
        <Stack
              flexDir="row"
              justifyContent="space-between"
              alignItems="center"
              pb="15px"
              pt="10px"
              borderBottom=" 1px dashed rgba(228, 230, 239, 1)"
            >
              <Text
                color="rgba(0, 0, 0, 1)"
                fontFamily="'Poppins', sans-serif"
                fontSize="18px"
                fontWeight="600"
                textAlign="left"
              >
                {educationData.title}
                {/* Mindset Training Academy */}
              </Text>

              <Link
                color="rgba(212, 32, 32, 1)"
                fontFamily="'Poppins', sans-serif"
                fontSize="14px"
                fontWeight="600"
                textAlign="left"
              >
                View All Lessons
              </Link>
            </Stack>

            <Stack
              flexDir="row"
              justifyContent='flex-start'
              alignItems="flex-start"
              w="full"
              flexWrap="wrap"
            >
              {educationData.lesson_array != undefined && educationData.lesson_array.map((item, id) => (
                <Stack w="33.3%" key={id} cursor={'pointer'} onClick={() => selectedLesson(item.id)} borderRadius={'12px'} _hover={{backgroundColor:'rgba(1, 1, 1, 0.05)'}}>
                  <Stack p="20px" w="full">
                    <Box position=" relative" w="full">
                      <Image
                        src={item.thumbnail !== undefined ? item.thumbnail : Img.Thumbnail}
                        alt="lessonThumbnail"
                        borderRadius="12px"
                        w="full"
                        h="195px"
                        minH={'195px'}
                        fit={'cover'}
                      />
                      <Box
                        position="absolute"
                        w="full"
                        h="full"
                        left="0"
                        top="0"
                        right="0"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Button
                          bg="rgba(0, 0, 0, 0.3)"
                          boxShadow="0px 0px 14px 0px rgba(0, 0, 0, 0.2)"
                          w="60px"
                          h="40px"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Image src={Icon.play} alt="play" />
                        </Button>
                      </Box>
                    </Box>
                    <Box w="full">
                      <Text
                        color="rgba(24, 28, 50, 1)"
                        fontFamily="'Poppins', sans-serif"
                        fontSize="16px"
                        fontWeight="700"
                        mt={'5px'}
                      >
                        {item.title ?? ''}
                      </Text>
                      <Text
                        color="rgba(126, 130, 153, 0.79)"
                        fontFamily="'Poppins', sans-serif"
                        fontSize="14px"
                        fontWeight="500"
                        my="8px"
                        lineHeight={'22px'}
                        noOfLines={3}
                      >
                        {item.description ?? ''}
                      </Text>
                      <Text
                        color="rgba(212, 32, 32, 1)"
                        fontFamily="'Poppins', sans-serif"
                        fontSize="14px"
                        fontWeight="600"
                      >
                        {fetchSenderForId(item.uploader ?? '')}
                        <span style={{ color: "rgba(161, 165, 183, 1)", paddingLeft:"5px"}}>
                          {convertDurationToString(item.duration ?? 0)}
                        </span>
                      </Text>
                    </Box>
                  </Stack>
                </Stack>
              ))}
            </Stack>
            {educationData.lesson_array !== undefined && educationData.lesson_array.length > 3 && (
                <Stack my="20px">
                <Button
                onClick={() => loadMoreLessons(educationData.id)}
                  colorScheme="red"
                  fontFamily="'Roboto',sans-serif"
                  fontSize="15px"
                  fontWeight="500"
                >
                  Load more
                </Button>
              </Stack>

            )}
        </>
    )
};

export default LessonsHolder;