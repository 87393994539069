import {
    Box,
    Button,
    Divider,
    FormControl,
    FormLabel,
    HStack,
    Image,
    Input,
    Menu,
    MenuButton,
    Stack,
    Text,
    Textarea,
    VStack,
  } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useToast } from "@chakra-ui/react";
import { Icon, Img } from "../../../../../contents/content";
import { AiFillHeart } from "react-icons/ai";
import { useRouter } from "../../../../../hooks/use-router";

const NewPost = () => {

    const router = useRouter();
    const toast = useToast();
    
    const [messageText, setMessageText] = useState('');

    const user = JSON.parse(localStorage.getItem('user'));

    const handleChange = (e) => {
        setMessageText(e.target.value);
        console.log(messageText);
    };

    const sendMessage = async () => {
        if (localStorage.getItem('user') === undefined || localStorage.getItem('user') === null) {
            return;
          };
          const user = JSON.parse(localStorage.getItem('user'));
          try {
            await fetch(
              `https://corsavailable.herokuapp.com/https://realwealth-api-0787684ad674.herokuapp.com/community/activity/${user.id}`, {
              method: "POST",
              headers: {
                "auth": "TEST",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,OPTIONS,PATCH,DELETE,POST,PUT",
                "Access-Control-Allow-Headers":
                  "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version",
                "Content-Type": "application/json",
                "Origin": "",
                "X-Requested-With": ""
              }
            }
            ).then(async (data) => {
              const json = await data.json();
              if (data.status === 200) {
                const responseDataResult = json["result"];
                if (responseDataResult !== undefined && responseDataResult !== '') {
                  console.log(responseDataResult);
                  router.refresh();
                }
              } else {
                const error = json['error'];
                if (error !== undefined) {
                  showError('Authentication', error);
                } else {
                  showError('Authentication', 'Failed to login. Please try again later');
                };
              };
            });
          } catch (error) {
            console.log(error)
          };
    };

    const showError = (title, description) => {
        toast({
          title: title ?? '',
          description: description ?? '',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      };

    useEffect(() => {

    },[]);

    return(
        <Stack
            bg="rgba(255, 255, 255, 1)"
            p="30px"
            w="full"
            borderRadius="12px"
          >
            <HStack alignItems="flex-start">
              <Box bg="rgba(243, 246, 249, 1)" p="0px" borderRadius="12px">
                <Image borderRadius="12px" height="43px" width={'43px'} maxWidth={'43px'} fit={'cover'}  src={user && user.photo !== undefined ? user.photo : ''} alt="avatarC1" />
              </Box>
              <Textarea
                onChange={handleChange}
                border="0"
                placeholder="Type your message here..."
                fontFamily="'Poppins',sans-serif"
                fontSize="16px"
                fontWeight="500"
                lineHeight="21px"
                color="rgba(1, 1, 1, 1)"
              ></Textarea>
            </HStack>
            <Divider my="5px" />
            <HStack w="full">
              <Box display="flex" alignItems="Center" w="full">
                <Menu>
                  {({ isOpen }) => (
                    <>
                      <MenuButton
                        isActive={isOpen}
                        as={Button}
                        bg="transparent"
                        _hover={{ bg: " transparent" }}
                        _focusWithin={{ bg: " transparent" }}
                        fontFamily="'Poppins', sans-serif"
                        fontSize="13px"
                        fontWeight="400"
                        lineHeight="20px"
                        color="rgba(128, 128, 143, 1)"
                        w="72px"
                      >
                        {isOpen ? "Normal" : "Italic"}
                      </MenuButton>
                    </>
                  )}
                </Menu>
                <Image src={Icon.arrow} alt="" />

                <Stack flexDir="row" justifyContent="space-between" w="full">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    w="70%"
                  >
                    <Button
                      ml="7px"
                      p="0"
                      bg="transparent"
                      _hover={{ bg: "transparent" }}
                    >
                      <Image src={Icon.B} alt="pic" />
                    </Button>
                    <Button
                      mr="4px"
                      p="0"
                      bg="transparent"
                      _hover={{ bg: "transparent" }}
                    >
                      <Image src={Icon.Italic} alt="pic" />
                    </Button>
                    <Button
                      mr="4px"
                      p="0"
                      bg="transparent"
                      _hover={{ bg: "transparent" }}
                    >
                      <Image src={Icon.Underline} alt="pic" />
                    </Button>
                    <Button
                      mr="4px"
                      p="0"
                      bg="transparent"
                      _hover={{ bg: "transparent" }}
                    >
                      <Image src={Icon.BulletList} alt="pic" />
                    </Button>
                    <Button
                      mr="4px"
                      p="0"
                      bg="transparent"
                      _hover={{ bg: "transparent" }}
                    >
                      <Image src={Icon.BulletList2} alt="pic" />
                    </Button>
                    <Button
                      mr="4px"
                      p="0"
                      bg="transparent"
                      _hover={{ bg: "transparent" }}
                    >
                      <Image src={Icon.ticon} alt="pic" />
                    </Button>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    w="30%"
                  >
                    <FormControl
                      ml="7px"
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <FormLabel htmlFor="file" textAlign="right" m="0" p="0">
                        <Image src={Icon.Clip} alt="pic" />
                      </FormLabel>
                      <Input type="file" display="none" id="file" />
                    </FormControl>

                    <Button
                      mr="4px"
                      p="0"
                      bg="transparent"
                      _hover={{ bg: "transparent" }}
                    >
                      <Image src={Icon.Markert} alt="pic" />
                    </Button>
                  </Box>
                </Stack>
              </Box>
            </HStack>
          </Stack>
    )
};

export default NewPost;