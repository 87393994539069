import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../Views/Home/Home";
import Dashboard from "../Views/Home/dashboard/Dashboard";
import Profile from "../Views/Home/Profile/Index";
import MyProfile from "../Views/Home/Profile/MyProfile/Index";
import Login from "../Auth/Login";
import Transactions from "../Views/Home/Transactions";
import ProtectedRoutes from "./ProtectedRoutes";
import Education from "../Views/Home/Education";
import VideoPlayer from "../Views/Home/VideoPlayer";
import Community from "../Views/Home/Community/index";

const Routing = () => {
  return (
    <Routes>
      <Route element={<ProtectedRoutes />}> 
        <Route element={<Home />}>
          <Route exact path="/" element={<Dashboard />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="subscription" element={<Transactions />} />
          <Route path="community" element={<Community />} />
          <Route path="education" element={<Education />} />
          <Route path="education/:lessonId" element={<VideoPlayer />} />
          <Route path="profile" element={<Profile />}>
          <Route path="" element={<MyProfile/>}/>
          <Route path="my-profile" element={<MyProfile/>}/>
          </Route>
        </Route>
      </Route>
      <Route path="/login" element={<Login/>}/>
    </Routes>
  );
};

export default Routing;
