import { Box, Button, Image, Stack, Text, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useRouter } from "../../../../../hooks/use-router";
import { DashboardData, GreetingBannerData, Icon, Img } from "../../../../../contents/content";
import { FaEllipsis, FaPenToSquare } from "react-icons/fa6";

const GreetingBanner = ({props}) => {
  
  const router = useRouter();

  const exploreNow = () => {
    router.push('/education')
  };

    return(
        <Stack
            boxShadow="0px 0px 20px 0px rgba(94, 98, 120, 0.04)"
            borderRadius="12px"
            w="full"
            h="277px"
            // p="30px"
            alignItems="flex-start"
            justifyContent="center"
            position="relative"
          >
            <Image
                src={Img.educationBg2}
                alt="educationBg2"
                borderRadius="20px"
                fit={'cover'}
                w="full"
                h="100%"
              />
              <Stack
                w="full"
                h="full"
                position="absolute"
                top="0"
                left="0"
                alignItems="flex-start"
                justifyContent="flex-end"
                p="30px"
              >
                <Text
              fontFamily="'Poppins', sans-serif"
              fontSize="28px"
              fontWeight="600"
              color="rgba(255, 255, 255, 1)"
              maxWidth={'70%'}
              lineHeight={'35px'}
            >
              {props.title}
            </Text>
            <Text
              fontFamily="'Poppins', sans-serif"
              fontSize="18px"
              fontWeight="500"
              color="rgba(255, 255, 255, 0.7)"
              maxWidth={'60%'}
            >
              {props.subtitle}
            </Text>
            <Button
            onClick={() => exploreNow()}
            borderRadius={'9px'}
              bg="rgba(212, 32, 32, 1)"
              fontFamily="'Roboto', sans-serif"
              fontSize="15px"
              fontWeight="500"
              color="rgba(255, 255, 255, 1)"
              mt={'10px'}
              p={'20px'}
            >
              {props.buttonNav}
            </Button>
              </Stack>
            
          </Stack>
    )
};

export default GreetingBanner;