
import logo from './assets/logo.svg'
import home from './assets/home.svg'
import MyTeams from './assets/icons/myTeams.svg'
import opportunity from './assets/icons/opportunity.svg'
import profile from './assets/icons/profile.svg'
import resources from './assets/icons/resources.svg'
import shopping from './assets/icons/shopping.svg'
import smartShip from './assets/icons/smartship.svg'
import upgrade from './assets/icons/upgrade.svg'
import wallet from './assets/icons/wallet.svg'
import xDollars from './assets/icons/xDollars.svg'
import notification from './assets/icons/notification.svg'
import Thumbnail from './assets/pic/Thumbnail.png'
import avatar1 from './assets/avatar/avatar1.png'
import avatar2 from './assets/avatar/avatar2.png'
import avatar3 from './assets/avatar/avatar3.png'
import avatar from './assets/avatar/Avatar.png'
import post from './assets/pic/post.png'
import bg1 from './assets/pic/bg1.png'
import EducationBg from './assets/pic/EducationBg.png'
import CommumityBg from './assets/pic/Commumity.png'
import walletBg from './assets/pic/walletBg.png'
import play from './assets/icons/play.svg'
import cart from './assets/icons/cart.svg'
import Equalizer from './assets/icons/Equalizer.svg'
import Blocks from './assets/icons/Blocks.svg'
import logout from './assets/icons/logout.svg'
import verified from './assets/icons/verified.svg'
import darkMode from './assets/icons/darkMode.svg'
import user from './assets/icons/user.svg'
import location from './assets/icons/location.svg'
import Warning from './assets/icons/Warning.svg'
import search from './assets/icons/search.svg'
import google from './assets/icons/google.svg'
import searchIcon from './assets/icons/searchIcon.svg'
import uk from './assets/flag/uk.png'
import australia from './assets/flag/australia.png'
import us from './assets/flag/us.png'
import france from './assets/flag/france.png'
import loginPng from './assets/pic/loginPng.png'
import educationBg2 from './assets/pic/educationBg2.png'
import edu1 from './assets/education/edu1.png'
import edu2 from './assets/education/edu2.png'
import edu3 from './assets/education/edu3.png'
import player from './assets/player/player.png'
import playerAvatar from './assets/player/avatar.png'
import lesson from './assets/player/lesson.png'
import avatarC1 from './assets/comunity/avatarC1.svg'
import avatarC2 from './assets/comunity/avatarC2.svg'
import avatarC3 from './assets/comunity/avatarC3.svg'
import arrow from './assets/icons/arrow.svg'
import B from './assets/icons/B.svg'
import Italic from './assets/icons/Itallic.svg'
import Underline from './assets/icons/Underline.svg'
import BulletList from './assets/icons/Bullet-list.svg'
import BulletList2 from './assets/icons/Bullet-list (1).svg'
import ticon from './assets/icons/tsvg.svg'
import Clip from './assets/icons/Clip.svg'
import Markert from './assets/icons/Markert.svg'
import Combined from './assets/icons/Combined.svg'
import Dots from './assets/icons/Dots.svg'
import pPhoto from './assets/post/Photo.png'

export const Img ={
    pPhoto,
    uk,
    australia,
    logo,
    us,
    france,
    Thumbnail,
    avatar1,
    avatar2,avatar3,
    bg1,
    post,
    walletBg,
    EducationBg,
    CommumityBg,
    avatar,
    loginPng,
    edu1,
    edu2,
    edu3,
    educationBg2,
    playerAvatar,
    lesson,
    player,
    avatarC1,
    avatarC2,
    avatarC3,
}

export const Icon = {
    Dots,
    Combined,
    Markert,
    Clip,
    ticon,
    Underline,
    BulletList,
    BulletList2,
    arrow,
    home,
    MyTeams,
    profile,
    resources,
    shopping,
    smartShip,
    upgrade,
    opportunity,
    wallet,
    xDollars,
    notification,
    play,
    Blocks,
    cart,
    Equalizer,
    logout,
    darkMode,
    verified,
    user,
    location,
    search,
    Warning,
    google,
    searchIcon,
    B,
    Italic, 
}

export const SideMenu = [
    {
        id:1,
        name:"Dashboard",
        icon:Icon.home,
        url:"/dashboard",

    },
    {
        id:6,
        name:"Education",
        icon:Icon.resources,
        url:"/education",
    },
    {
        id:6,
        name:"Community",
        icon:Icon.MyTeams,
        url:"/community",
    },
    {
        id:5,
        name:"Subscription",
        icon:Icon.wallet,
        url:"/subscription",
    },  
    
    // {
    //     id:3,
    //     name:"Live Calls",
    //     icon:Icon.upgrade,
    //     url:"livecalls",
    // },
    // {
    //     id:6,
    //     name:"Marketplace",
    //     icon:Icon.shopping,
    //     url:"/marketplace",
    // },  
    {
        id:2,
        name:"My Profile",
        icon:Icon.profile,
        url:"/profile"
    },
   
]
 
export const DashboardData=[
    {
        icon:Icon.cart,
        name:"Community",
        subName:"Let's discuss all things wealth",
        bColor:"rgba(246, 78, 96, 1)",
        bShape:Img.EducationBg
    },
    {
        icon:Icon.Blocks,
        name:"Education",
        subName:"Stay sharp on your most valuable skills",
        bColor:"rgba(105, 147, 255, 1)",
        bShape:Img.CommumityBg
    },
    {
        icon:Icon.Equalizer,
        name:"Subscriptions",
        subName:"Discover all Real Wealth transactions",
        bColor:"rgba(27, 197, 189, 1)",
        bShape:Img.walletBg
    }
]

export const GreetingBannerData = {
    title: 'Explore our extensive Wealth Building Academy',
    subtitle: "Courses, Community, Live Calls & More",
    buttonNav: "Explore Now"
};

export const MockAnnouncements = [
    {
        sender: 'u89fy94hn29u3',
        senderName: 'Dahmeyon McDonald',
        timestamp: 1099235,
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        deleted: false,
        multimedia: []
    },
    {
        sender: 'u89fy92hn29u3',
        senderName: 'Kevin Daniels',
        timestamp: 1099235,
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        deleted: false,
        admin: true,
        multimedia: []
    },
    {
        sender: 'u89fy94h3g9u3',
        senderName: 'Dahmeyon McDonald',
        timestamp: 1099235,
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        deleted: false,
        multimedia: [{
            id: 'asf34f4f',
            type: 'image',
            height: 400,
            width: 500,
            url: 'http://'
        }]
    }
];

export const MockRecentCourse = {
    id: 'u89fy94hn29u3',
    title: 'Wealth Academy - Real Estate 101',
    description: "We’ve been focused on making the from v4 to v5 but we have also not been afraid to step aw...",
    uploaded: 1099235,
    thumbnail: Img.Thumbnail,
    current_timestamp: 4430
};

export const TransactionHistory=[

    // {
    //     id:1,
    //     date:"Nov 01, 2020",
    //     orderID:"002445788",
    //     details:"Adjustment - Referral Dept 1",
    //     amount:"$30.00",
    //     invoice:"Download"
    // },
    // {
    //     id:2,
    //     date:"Oct 24, 2020",
    //     orderID:"002445788",
    //     details:"Adjustment - Sling Shot Double Bonus",
    //     amount:"$150.00",
    //     invoice:"Download"
    // },
    // {
    //     id:3,
    //     date:"Oct 08, 2020",
    //     orderID:"002445788",
    //     details:"Adjustment - Referral Level One",
    //     amount:"$60.00",
    //     invoice:"Download"
    // },
    // {
    //     id:4,
    //     date:"Sep 15, 2020",
    //     orderID:"002445788",
    //     details:"Adjustment - Referral Level One",
    //     amount:"$60.00",
    //     invoice:"Download"
    // },    
    // {
    //     id:5,
    //     date:"May 30, 2020",
    //     orderID:"002445788",
    //     details:"Adjustment - Referral Level One",
    //     amount:"$30.00",
    //     invoice:"Download"
    // },
    // {
    //     id:6,
    //     date:"Apr 22, 2020",
    //     orderID:"002445788",
    //     details:"Adjustment - Referral Dept 1",
    //     amount:"$30.00",
    //     invoice:"Download"
    // }
    // ,
    // {
    //     id:7,
    //     date:"Feb 09, 2020",
    //     orderID:"002445788",
    //     details:"Adjustment - Referral Dept 1",
    //     amount:"$60.00",
    //     invoice:"Download"
    // },
    // {
    //     id:8,
    //     date:"Jan 17, 2020",
    //     orderID:"002445788",
    //     details:"Adjustment - Referral Dept 1",
    //     amount:"$60.00",
    //     invoice:"Download"
    // }
    // ,
    // {
    //     id:9,
    //     date:"Jan 04, 2020",
    //     orderID:"002445788",
    //     details:"Adjustment - Referral Dept 1",
    //     amount:"$150.00",
    //     invoice:"Download"
    // }
]

export const EducationData = [
    {
        id:1,
        pic:Img.edu1,
        title:"Metronic Admin - How To Started the Dashboard Tutorial ",
        subtitle:"We’ve been focused on making a the from also not been afraid to and step away been focused create eye",
        author:"David Morgan on ",
        date:"Apr 27 2021",
        url:"" 
    },
    {
        id:2,
        pic:Img.edu2,
        title:"Metronic Admin - How To Started the Dashboard Tutorial ",
        subtitle:"We’ve been focused on making a the from also not been afraid to and step away been focused create eye",
        author:"David Morgan on ",
        date:"Apr 27 2021",
        url:"" 
    },
    {
        id:3,
        pic:Img.edu3,
        title:"Metronic Admin - How To Started the Dashboard Tutorial ",
        subtitle:"We’ve been focused on making a the from also not been afraid to and step away been focused create eye",
        author:"David Morgan on ",
        date:"Apr 27 2021",
        url:"" 
    },
    {
        id:4,
        pic:Img.edu1,
        title:"Metronic Admin - How To Started the Dashboard Tutorial ",
        subtitle:"We’ve been focused on making a the from also not been afraid to and step away been focused create eye",
        author:"David Morgan on ",
        date:"Apr 27 2021",
        url:"" 
    },
    {
        id:5,
        pic:Img.edu2,
        title:"Metronic Admin - How To Started the Dashboard Tutorial ",
        subtitle:"We’ve been focused on making a the from also not been afraid to and step away been focused create eye",
        author:"David Morgan on ",
        date:"Apr 27 2021",
        url:"" 
    },
    {
        id:6,
        pic:Img.edu2,
        title:"Metronic Admin - How To Started the Dashboard Tutorial ",
        subtitle:"We’ve been focused on making a the from also not been afraid to and step away been focused create eye",
        author:"David Morgan on ",
        date:"Apr 27 2021",
        url:"" 
    },
]

export const upComingLesson =[
    // {
    //     id:6,
    //     pic:Img.lesson,
    //     title:"Property Management 101 - Intro",
    //     time:"32 min 17 sec",
    // },
    // {
    //     id:6,
    //     pic:Img.lesson,
    //     title:"Property Management 101 - Intro",
    //     time:"32 min 17 sec",
    // },
    // {
    //     id:6,
    //     pic:Img.lesson,
    //     title:"Property Management 101 - Intro",
    //     time:"32 min 17 sec",
    // },
    
]