import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  Menu,
  MenuButton,
  Stack,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import TopBar from "../TopBar";
import { Icon, Img } from "../../../contents/content";
import { AiFillHeart } from "react-icons/ai";
import NewPost from "./components/NewPost";
import Banner from "./components/Banner";

const Community = () => {

  const toast = useToast();

  const [posts, setPosts] = useState([]);
  const [pagination, setPagination] = useState(0);

  const [time, setTime] = useState(new Date());

  const expandImage = (url) => {
    //
  };
  
  const fetchFeedPosts = async () => {
    try {
      await fetch(
        `https://corsavailable.herokuapp.com/https://realwealth-api-0787684ad674.herokuapp.com/community/posts/0`, {
        method: "GET",
        headers: {
          "auth": "TEST",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,OPTIONS,PATCH,DELETE,POST,PUT",
          "Access-Control-Allow-Headers":
            "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version",
          "Content-Type": "application/json",
          "Origin": "",
          "X-Requested-With": ""
        }
      }
      ).then(async (data) => {
        const json = await data.json();
        if (data.status === 200) {
          const responseDataResult = json["result"];
          if (responseDataResult !== undefined && responseDataResult !== '') {
            console.log(responseDataResult);
            setPosts(responseDataResult);
            // router.refresh();
          }
        } else {
          const error = json['error'];
          if (error !== undefined) {
            showError('Authentication', error);
          } else {
            showError('Authentication', 'Failed to login. Please try again later');
          };
        };
      });
    } catch (error) {
      console.log(error)
    };
  };

  const showError = (title, description) => {
    toast({
      title: title ?? '',
      description: description ?? '',
      status: 'error',
      duration: 3000,
      isClosable: true,
    })
  };

  const loadMoreFeedPosts = async () => {
    if (posts.length > 0) {
      setPagination(posts[posts.length - 1].timestamp);
    };

    try {
      await fetch(
        `https://corsavailable.herokuapp.com/https://realwealth-api-0787684ad674.herokuapp.com/community/posts/${posts[posts.length - 1].timestamp}`, {
        method: "GET",
        headers: {
          "auth": "TEST",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,OPTIONS,PATCH,DELETE,POST,PUT",
          "Access-Control-Allow-Headers":
            "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version",
          "Content-Type": "application/json",
          "Origin": "",
          "X-Requested-With": ""
        }
      }
      ).then(async (data) => {
        const json = await data.json();
        if (data.status === 200) {
          const responseDataResult = json["result"];
          if (responseDataResult !== undefined && responseDataResult !== '') {
            console.log(responseDataResult);
            var prefilteredDict = posts;
            await responseDataResult.forEach((post) => {
              prefilteredDict.push(post);
            });
            setPosts(prefilteredDict);
            console.log(prefilteredDict);
          }
        } else {
          const error = json['error'];
          if (error !== undefined) {
            showError('Authentication', error);
          } else {
            showError('Authentication', 'Failed to login. Please try again later');
          };
        };
      });
    } catch (error) {
      console.log(error)
    };
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  };

  const convertTimestampToDateString = (timestampString) => {
    const date = new Date(timestampString);
    // Format date to "MMM dd, yyyy HH:mm"
    const options = {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    const formattedDate = date.toLocaleString("en-US", options);
    return formattedDate;
  };

  const fetchSenderForId = (sender) => {
    const usersObj = localStorage.getItem('users');
    if (usersObj !== undefined && usersObj !== null) {
      const users = JSON.parse(usersObj);
      const filtered = users.filter((user) => user.id === sender);
      if (filtered.length > 0) {
        return filtered[0].name ?? ''
      } else {
        return ''
      };
    } else {
      return ''
    };
  };

  const fetchSenderPhotoForId = (sender) => {
    const usersObj = localStorage.getItem('users');
    if (usersObj !== undefined && usersObj !== null) {
      const users = JSON.parse(usersObj);
      const filtered = users.filter((user) => user.id === sender);
      if (filtered.length > 0) {
        const targetUser = filtered[0]
        return targetUser.photo !== '' ? targetUser.photo : Img.avatarC3
      } else {
        return Img.avatarC3
      };
    } else {
      return Img.avatarC3
    };
  };

  const fetchSenderPrivilagesForId = (sender) => {
    const usersObj = localStorage.getItem('users');
    if (usersObj !== undefined && usersObj !== null) {
      const users = JSON.parse(usersObj);
      const filtered = users.filter((user) => user.id === sender);
      if (filtered.length > 0) {
        const targetUser = filtered[0]
        return targetUser.verified !== '' ? targetUser.verified : false;
      } else {
        return false;
      };
    } else {
      return false;
    };
  };

  useEffect(() => {
    fetchFeedPosts();
  },[]);

  return (
    <div>
      <VStack bg="#f9fafb" w="full">
        <TopBar IsSearch="true" title="Community" subtitle={"Let's connect and share the wealth building knowledge"} />
        
        <Stack px="50px" w="full" py="40px">
          <Banner/>
          <NewPost/>
          {/* Posts */}
          {posts && posts.length > 0 && posts.map((post) => (
            <Stack
            // bg="rgba(255, 255, 255, 1)"
            bg={fetchSenderPrivilagesForId(post.sender) === true ? "linear-gradient(269.66deg, #2A34BF 0.24%, #4285F4 99.76%)" : "rgba(255, 255, 255, 1)"}
            px="30px"
            pt="30px"
            pb="10px"
            w="full"
            borderRadius="12px"
            my="10px"
          >
            <HStack justifyContent="space-between">
              <HStack alignItems="flex-start">
                <Box bg="rgba(243, 246, 249, 1)" p="0px" borderRadius="12px">
                <Image borderRadius="12px" height="43px" width={'43px'} maxWidth={'43px'} fit={'cover'} src={fetchSenderPhotoForId(post.sender)} alt="avatarC1" />
                </Box>
                <Box>
                  <Stack flexDir={'row'}>
                  <Text
                    fontFamily="'Poppins', sans-serif"
                    fontSize="14px"
                    fontWeight={fetchSenderPrivilagesForId(post.sender) === true ? "700" : "600"} 
                    lineHeight="20px"
                    color={fetchSenderPrivilagesForId(post.sender) === true ? "rgba(255, 214, 0, 1)" : "rgba(63, 66, 84, 1)"} 
                    // color="rgba(63, 66, 84, 1)"
                  >
                    {fetchSenderForId(post.sender)}
                    
                  </Text>
                  {fetchSenderPrivilagesForId(post.sender) === true && (
                    <Image h={'20px'} src={Icon.verified}></Image>
                  )}
                  </Stack>
                  <Text
                    fontFamily="'Poppins', sans-serif"
                    fontSize="13px"
                    fontWeight="500"
                    lineHeight="20px"
                    color="rgba(181, 181, 195, 1)"
                  >
                    {convertTimestampToDateString(post.timestamp)}
                    {/* Yestarday at 5:06 PM */}
                  </Text>
                </Box>
              </HStack>
              <Box>
                <Button p="0" bg="transparent" _hover={{ bg: "transparent" }}>
                  <Image src={Icon.Dots} alt="Dots" />
                </Button>
              </Box>
            </HStack>
            <Stack w="full" py="10px">
              {post.multimedia && post.multimedia.length > 0 && (
                <Image onClick={() => expandImage(post.multimedia[0].url)} cursor={'pointer'} src={post.multimedia[0].url ?? Img.pPhoto} maxH={'400px'} minH={'250px'} alt="" fit={'cover'} borderRadius={'12px'} my="10px"/>
              )}
              <Text
                fontFamily="'Poppins', sans-serif"
                fontSize="16px"
                  fontWeight="500"
                lineHeight="25px"
                // color="rgba(63, 66, 84, 1)"
                color={fetchSenderPrivilagesForId(post.sender) === true ? "rgba(255, 255, 255, 1)" : "rgba(63, 66, 84, 1)"} 
              >
                {post.content}
              </Text>
            </Stack>
            <Stack>
              <HStack>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Image src={Icon.Combined} alt="" mr="10px" />
                  <Text
                    fontFamily="'Poppins', sans-serif"
                    fontSize="15px"
                    fontWeight="600"
                    lineHeight="21px"
                    color={fetchSenderPrivilagesForId(post.sender) === true ? "rgba(255, 255, 255, 1)" : "rgba(126, 130, 153, 1)"} 
                    // color="rgba(126, 130, 153, 1)"
                  >
                    {post.comments ?? '0'}
                  </Text>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Button
                    p="0"
                    bg="transparent"
                    _hover={{ bg: "transparent" }}
                    color={fetchSenderPrivilagesForId(post.sender) === true ? "rgba(255, 255, 255, 1)" : "p1"} 
                    // color="p1"
                  >
                    <AiFillHeart />
                  </Button>
                  <Text
                    fontFamily="'Poppins', sans-serif"
                    fontSize="15px"
                    fontWeight="600"
                    lineHeight="21px"
                    color={fetchSenderPrivilagesForId(post.sender) === true ? "rgba(255, 255, 255, 1)" : "rgba(126, 130, 153, 1)"} 
                    // color="rgba(126, 130, 153, 1)"
                  >
                    {post.likes ?? '0'}
                  </Text>
                </Box>
              </HStack>
            </Stack>
            <Divider my="5px" />
            <HStack>
              <FormControl w="full" overflow="hidden">
                <Input type="text" placeholder="Reply.." border="0" />
              </FormControl>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                w="30%"
              >
                <FormControl
                  ml="7px"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <FormLabel htmlFor="file" textAlign="right" m="0" p="0">
                    <Image src={Icon.Clip} alt="pic" />
                  </FormLabel>
                  <Input type="file" display="none" id="file" />
                </FormControl>
                <Button
                  mr="4px"
                  p="0"
                  bg="transparent"
                  _hover={{ bg: "transparent" }}
                >
                  <Image src={Icon.Markert} alt="pic" />
                </Button>
              </Box>
            </HStack>
          </Stack>

          ))}
          <Stack w="100%" my="20px">
              <Button
              onClick={() => loadMoreFeedPosts()}
                colorScheme="red"
                fontFamily="'Roboto',sans-serif"
                fontSize="15px"
                fontWeight="500"
              >
                Load more posts
              </Button>
            </Stack>
        </Stack>
        
      </VStack>
    </div>
  );
};

export default Community;
