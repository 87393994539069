import {
  Box,
  Button,
  Image,
  Link,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import TopBar from "../TopBar";
import { EducationData, Icon, Img } from "../../../contents/content";
import Banner from "../Community/components/Banner";
import LessonsHolder from "./components/LessonsHolder";
import { useRouter } from "../../../hooks/use-router";

const Education = () => {
    const WealthAcademy = EducationData.slice(0,2);
    const router = useRouter();

    const [allLessons, setAllLessons] = useState([]);
    const [allCourses, setAllCourses] = useState([]);

    const [educationData, setEducationData] = useState([]);

    const fetchAllCourses = async () => {
      try {
        await fetch(
          "https://corsavailable.herokuapp.com/https://realwealth-api-0787684ad674.herokuapp.com/education/courses", {
            method: "GET",
            headers: {
              "auth": "TEST",
              "Access-Control-Allow-Credentials": true,
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET,OPTIONS,PATCH,DELETE,POST,PUT",
              "Access-Control-Allow-Headers":
                "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version",
              "Content-Type": "application/json",
              "Origin": "",
              "X-Requested-With": ""
            }
          }).then(async (data) => {
              const json = await data.json();
              if (data.status === 200) {
                  const responseDataResult = json["result"];
                  if (responseDataResult !== undefined && responseDataResult !== '') {
                      fetchAllLessons(responseDataResult);
                  } else {
                      console.log('Failed to store courses');
                      fetchAllLessons([]);
                  }
              } else {
                  const error = json['error'];
                  if (error !== undefined) {
                      console.log('Failed to find courses');
                  } else {
                      console.log('Failed to find courses');
                  };
              };
          });
      } catch (error) {
          console.log(error);
      };
    };

    const fetchAllLessons = async (courses) => {
      console.log(courses);
      try {
        await fetch(
          "https://corsavailable.herokuapp.com/https://realwealth-api-0787684ad674.herokuapp.com/education/lessons", {
            method: "GET",
            headers: {
              "auth": "TEST",
              "Access-Control-Allow-Credentials": true,
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET,OPTIONS,PATCH,DELETE,POST,PUT",
              "Access-Control-Allow-Headers":
                "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version",
              "Content-Type": "application/json",
              "Origin": "",
              "X-Requested-With": ""
            }
          }).then(async (data) => {
              const json = await data.json();
              if (data.status === 200) {
                  const responseDataResult = json["result"];
                  if (responseDataResult !== undefined && responseDataResult !== '') {
                      performFiltering(responseDataResult, courses);
                  } else {
                      console.log('Failed to store lessons');
                      performFiltering([], courses);
                  }
              } else {
                  const error = json['error'];
                  if (error !== undefined) {
                      console.log('Failed to find lessons');
                  } else {
                      console.log('Failed to find lessons');
                  };
              };
          });
      } catch (error) {
          console.log(error);
      };
    };

    const performFiltering = async (lessons, courses) => {
      console.log('Filtering lessons: ' + lessons.length);
      console.log('Filtering courses: ' + courses.length);

      setAllCourses(courses);
      setAllLessons(lessons);

      var parsedCourses = courses;
      await lessons.forEach((lesson) => {
        const filteredCourses = parsedCourses.filter((course) => course.id == lesson.course);
        if (filteredCourses.length > 0) {
          const index = parsedCourses.findIndex((course) => course.id == lesson.course);
          if (parsedCourses[index].lesson_array === undefined || parsedCourses[index].lesson_array === null) {
            parsedCourses[index].lesson_array = [];
            parsedCourses[index].lesson_array.push(lesson);
          } else {
            parsedCourses[index].lesson_array.push(lesson);
          };
        };
      });
      setEducationData(parsedCourses);
    };

    const userSelectedLesson = (lesson) => {
      router.push(`/education/${lesson}`, {});
    };

    useEffect(() => {
      fetchAllCourses();
    },[]);

  return (
    <div>
      <VStack bg="rgba(249, 250, 251, 1)" paddingBottom="40px">
        <TopBar title="Education" subtitle={"Explore the wealth building catalog"} IsSearch="true" />
        <Stack px="50px" w="full" py="40px">
          <Banner/>
          <Stack
            bg="rgba(255, 255, 255, 1)"
            p="30px"
            w="full"
            borderRadius="12px"
          >
            {educationData && educationData.length > 0 && educationData.map((education) => (
              <LessonsHolder educationData={education} selectedLesson={userSelectedLesson}/>
            ))}
          </Stack>
        </Stack>
      </VStack>
    </div>
  );
};

export default Education;
