import { Box, Button, Image, Stack, Text, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { DashboardData, Icon, Img } from "../../../../../contents/content";
import { FaEllipsis, FaPenToSquare } from "react-icons/fa6";

const QuickNav = () => {

    return(
    <>
        {DashboardData.map((item, index) => {
            return (
              <Stack w="33.3%" p="25px" key={index} cursor={'pointer'}>
                <Box
                  bg={item.bColor}
                  width="full"
                  p="21px"
                  borderRadius="10px"
                  display="flex"
                  alignItems="flex-start"
                  flexDir="column"
                  justifyContent="center"
                  position="relative"
                  overflow="hidden"
                >
                  <Image src={item.icon} alt="cart" py="10px" />
                  <Text
                  noOfLines={1}
                    fontFamily="'Poppins', sans-serif"
                    fontSize="17px"
                    fontWeight="600"
                    color="rgba(255, 255, 255, 1)"
                  >
                    {item.name}
                  </Text>
                  <Text
                  noOfLines={1}
                    fontFamily="'Poppins', sans-serif"
                    fontSize="12px"
                    fontWeight="500"
                    color="rgba(255, 255, 255, 1)"
                  >
                    {item.subName}
                  </Text>
  
                  <Image
                    src={item.bShape}
                    position="absolute"
                    right="0"
                    top="0"
                  />
                </Box>
              </Stack>
            );
          })}
    </>
    )
};

export default QuickNav;