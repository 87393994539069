import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    Image,
    Input,
    Link,
    Progress,
    Stack,
    Switch,
    Text,
    VStack,
  } from "@chakra-ui/react";
  import React, { useEffect, useState } from "react";
  import { Icon, Img } from "../../../../../../contents/content";

const DeactivateAccount = ({props}) => {

    return(
        <VStack paddingX="50px" w="full" py="25px">
          <Stack
            w="full"
            bg="rgba(255, 255, 255, 1)"
            borderRadius="12px"
            px="32px"
            py="32px"
          >
            <Box>
              <Text
                fontFamily="'Roboto', sans-serif"
                fontSize="20px"
                textAlign="left"
                fontWeight="700"
                color="rgba(63, 66, 84, 1)"
              >
                Deactivate Account
              </Text>
            </Box>
            <Divider my="15px" />
            <Stack w="full">
              <Stack
                p="20px"
                bg="#fff9f4"
                borderRadius="12px"
                border="1px dashed rgba(248, 156, 71, 1)"
              >
                <Stack flexDir="row" alignItems="flex-start">
                  <Image src={Icon.Warning} />
                  <Box w="50%">
                    <Text
                      fontFamily="'Roboto', sans-serif"
                      fontSize="16px"
                      textAlign="left"
                      fontWeight="700"
                      color="rgba(63, 66, 84, 1)"
                    >
                      You are deactivatiing your account
                    </Text>
                    <Text
                      fontFamily="'Roboto', sans-serif"
                      fontSize="16px"
                      textAlign="left"
                      fontWeight="500"
                      color="rgba(126, 130, 153, 1)"
                    >
                      For extra security, this requires you to confirm your
                      email or phone number when you reset your password.{" "}
                      <Link to="" color="rgba(242, 63, 68, 1)">
                        {" "}
                        Learn more
                      </Link>
                    </Text>
                  </Box>
                </Stack>
              </Stack>
              <Box
                w="100%"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                py="15px"
              >
                <FormControl w="100%" px="20px">
                  <Checkbox
                    size="lg"
                    colorScheme="red"
                    fontFamily="'Roboto', sans-serif"
                    fontSize="15px"
                    textAlign="left"
                    fontWeight="500"
                    color="rgba(63, 66, 84, 1)"
                  >
                    Confirm Account Deactivation
                  </Checkbox>
                </FormControl>
              </Box>
            </Stack>
            <Divider my="15px" />
            <Stack w="full" justifyContent="flex-end" alignItems="flex-end">
              <Button
                ml="15px"
                bg="p2"
                height="42px"
                fontFamily="'Roboto', sans-serif"
                fontSize="15px"
                textAlign="left"
                fontWeight="500"
                color="rgba(255, 255, 255, 1)"
                _hover={{ bg: "p1" }}
              >
                Deactivate Account
              </Button>
            </Stack>
          </Stack>
        </VStack>
    )
};

export default DeactivateAccount;