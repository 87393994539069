import { Box, Button, Image, Stack, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useToast } from "@chakra-ui/react";
import { DashboardData, Icon, Img } from "../../../../../contents/content";
import { FaEllipsis, FaPenToSquare } from "react-icons/fa6";

const Announcements = ({ announcements }) => {

  const toast = useToast();

  const [posts, setPosts] = useState([]);
  const [pagination, setPagination] = useState(0);

  const [time, setTime] = useState(new Date());

  const fetchFeedPosts = async () => {
    try {
      await fetch(
        `https://corsavailable.herokuapp.com/https://realwealth-api-0787684ad674.herokuapp.com/announcements/posts/0`, {
        method: "GET",
        headers: {
          "auth": "TEST",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,OPTIONS,PATCH,DELETE,POST,PUT",
          "Access-Control-Allow-Headers":
            "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version",
          "Content-Type": "application/json",
          "Origin": "",
          "X-Requested-With": ""
        }
      }
      ).then(async (data) => {
        const json = await data.json();
        if (data.status === 200) {
          const responseDataResult = json["result"];
          if (responseDataResult !== undefined && responseDataResult !== '') {
            console.log(responseDataResult);
            setPosts(responseDataResult);
            // router.refresh();
          }
        } else {
          const error = json['error'];
          if (error !== undefined) {
            showError('Authentication', error);
          } else {
            showError('Authentication', 'Failed to login. Please try again later');
          };
        };
      });
    } catch (error) {
      console.log(error)
    };
  };

  const showError = (title, description) => {
    toast({
      title: title ?? '',
      description: description ?? '',
      status: 'error',
      duration: 3000,
      isClosable: true,
    })
  };

  const loadMoreFeedPosts = async () => {
    if (posts.length > 0) {
      setPagination(posts[posts.length - 1].timestamp);
    };

    try {
      await fetch(
        `https://corsavailable.herokuapp.com/https://realwealth-api-0787684ad674.herokuapp.com/announcements/posts/${posts[posts.length - 1].timestamp}`, {
        method: "GET",
        headers: {
          "auth": "TEST",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,OPTIONS,PATCH,DELETE,POST,PUT",
          "Access-Control-Allow-Headers":
            "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version",
          "Content-Type": "application/json",
          "Origin": "",
          "X-Requested-With": ""
        }
      }
      ).then(async (data) => {
        const json = await data.json();
        if (data.status === 200) {
          const responseDataResult = json["result"];
          if (responseDataResult !== undefined && responseDataResult !== '') {
            console.log(responseDataResult);
            var prefilteredDict = posts;
            await responseDataResult.forEach((post) => {
              prefilteredDict.push(post);
            });
            setPosts(prefilteredDict);
            console.log(prefilteredDict);
          }
        } else {
          const error = json['error'];
          if (error !== undefined) {
            showError('Error', error);
          } else {
            showError('Error', 'Failed to login. Please try again later');
          };
        };
      });
    } catch (error) {
      console.log(error)
    };
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  };

  const convertTimestampToDateString = (timestampString) => {
    const date = new Date(timestampString);
    // Format date to "MMM dd, yyyy HH:mm"
    const options = {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    const formattedDate = date.toLocaleString("en-US", options);
    return formattedDate;
  };

  const fetchSenderForId = (sender) => {
    const usersObj = localStorage.getItem('users');
    if (usersObj !== undefined && usersObj !== null) {
      const users = JSON.parse(usersObj);
      const filtered = users.filter((user) => user.id === sender);
      if (filtered.length > 0) {
        return filtered[0].name ?? ''
      } else {
        return ''
      };
    } else {
      return ''
    };
  };

  const fetchSenderPhotoForId = (sender) => {
    const usersObj = localStorage.getItem('users');
    if (usersObj !== undefined && usersObj !== null) {
      const users = JSON.parse(usersObj);
      const filtered = users.filter((user) => user.id === sender);
      if (filtered.length > 0) {
        const targetUser = filtered[0]
        return targetUser.photo !== '' ? targetUser.photo : Img.avatarC3
      } else {
        return Img.avatarC3
      };
    } else {
      return Img.avatarC3
    };
  };

  const fetchSenderPrivilagesForId = (sender) => {
    const usersObj = localStorage.getItem('users');
    if (usersObj !== undefined && usersObj !== null) {
      const users = JSON.parse(usersObj);
      const filtered = users.filter((user) => user.id === sender);
      if (filtered.length > 0) {
        const targetUser = filtered[0]
        return targetUser.verified !== '' ? targetUser.verified : false;
      } else {
        return false;
      };
    } else {
      return false;
    };
  };

  const expandImage = (url) => {

  };

  useEffect(() => {
    fetchFeedPosts();
  },[]);
    
    return(
        <Stack
            bg="rgba(255, 255, 255, 1)"
            w="full"
            h="auto"
            mt="40px"
            borderRadius="12px"
            p="25px"
          >
            <Stack flexDir="row" w="full" alignItems="center">
              <Box w="80%" mb={'10px'}>
                <Text
                  fontFamily="'Poppins', sans-serif"
                  fontSize="17px"
                  fontWeight="600"
                  lineHeight="21px"
                  textAlign="left"
                  color="rgba(63, 66, 84, 1)"
                >
                  Announcements
                </Text>
                <Text
                  fontFamily="'Poppins', sans-serif"
                  fontSize="12px"
                  fontWeight="500"
                  lineHeight="21px"
                  pt="0px"
                  textAlign="left"
                  color="rgba(181, 181, 195, 1)"
                >
                  Your most recent announcements
                </Text>
              </Box>
              <Box textAlign="right" w="20%">
                <Button
                  bg="rgb(161 165 183 / 19%)"
                  h="35px"
                  p="0"
                  w="25px"
                  borderRadius="10px"
                  color="rgba(161, 165, 183, 1)"
                >
                  <FaPenToSquare />
                </Button>
              </Box>
            </Stack>
            { posts && posts.length > 0 && posts.map((announcement, id) => (
              <Stack key={id}
              w="full"
              alignItems="flex-start"
              bg={fetchSenderPrivilagesForId(announcement.sender) === true ? "linear-gradient(269.66deg, #2A34BF 0.24%, #4285F4 99.76%)" : "rgba(238, 241, 245, 1)"}
              p="18px"
              m="0px"
              borderRadius="12px"
            >
              <Box display="flex" alignItems="center">
                <Image src={fetchSenderPhotoForId(announcement.sender)} alt="avatar3" w="33px" h={'30px'} fit={'cover'} borderRadius={'5px'} mr="8px" />
                <Box>
                  <Text
                    fontFamily="'Poppins', sans-serif"
                    fontSize="12px"
                    fontWeight="600"
                    color={fetchSenderPrivilagesForId(announcement.sender) === true ? "rgba(255, 255, 255, 1)" : "rgba(63, 66, 84, 1)"} 
                  >
                    {fetchSenderForId(announcement.sender) ?? ''}
                  </Text>
                  <Text
                    fontFamily="'Poppins', sans-serif"
                    fontSize="12px"
                    fontWeight="600"
                    color={fetchSenderPrivilagesForId(announcement.sender) === true ? "rgba(255, 255, 255, 0.76)" : "rgba(181, 181, 195, 1)"}
                  >
                    {convertTimestampToDateString(announcement.timestamp ?? 0)}
                  </Text>
                </Box>
              </Box>
              <Box py="5px">
                <Text
                  fontFamily="'Poppins', sans-serif"
                  fontSize="13px"
                  fontWeight="500"
                  color={fetchSenderPrivilagesForId(announcement.sender) === true ? "rgba(255, 255, 255, 1)" : "rgba(0, 0, 0, 1)"}  
                >
                  {announcement.content}
                </Text>
                {announcement.multimedia && announcement.multimedia.length > 0 && (
                  <Image
                  onClick={() => expandImage(announcement.multimedia[0].url)}
                  cursor={'pointer'}
                  src={announcement.multimedia[0].url ?? ''}
                  alt="post"
                  w="full"
                  h="250px"
                  my="20px"
                  minH={'250px'}
                  borderRadius={'12px'}
                  objectFit="cover"
                />
                )}
              </Box>
            </Stack>
            ))}
            <Stack w="full" mt="10px">
              <Button
              onClick={() => loadMoreFeedPosts()}
                w="full"
                h="45px"
                borderRadius="9px"
                bg="rgba(212, 32, 32, 1)"
                color="rgba(255, 255, 255, 1)"
                fontFamily="'Roboto', sans-serif"
                fontSize="15px"
                fontWeight="500"
                _hover={{
                  bg: "rgba(212, 32, 32, 1)",
                  transition: "0.3s all",
                }}
              >
                See more announcements
              </Button>
            </Stack>
          </Stack>
    )
}

export default Announcements;