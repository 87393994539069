import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    Image,
    Input,
    Link,
    Progress,
    Stack,
    Switch,
    Text,
    VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useRouter } from "../../../../../../hooks/use-router";
import { Icon, Img } from "../../../../../../contents/content";

const BasicInfo = ({ props, showError }) => {

    const router = useRouter();
    const user = JSON.parse(localStorage.getItem('user'));

    const [userChanges, setUserChanges] = useState();

    const handleChange = (e) => {
        const id = e.target.id;
        const value = e.target.value;
        const updatedUserChanges = { ...props };
        updatedUserChanges[id] = value;
        setUserChanges(updatedUserChanges);
        e.preventDefault();
    };

    const handleEmailChecked = () => {
        const updatedUserChanges = { ...userChanges };

        if (userChanges.preferred_contact.includes('email') && userChanges.preferred_contact.includes('phone')) {
            updatedUserChanges.preferred_contact = ['phone']
        } else {
            updatedUserChanges.preferred_contact = []
        };

        setUserChanges(updatedUserChanges);
    };

    const handlePhoneChecked = () => {
        // const updatedUserChanges = { ...userChanges };
        // if (userChanges.preferred_contact !== undefined) {
        //     if (userChanges.preferred_contact.includes('email') && userChanges.preferred_contact.includes('phone')) {
        //         updatedUserChanges.preferred_contact = ['email']
        //     } else if (userChanges.preferred_contact.includes('email') == false && userChanges.preferred_contact.includes('phone')) {
        //         updatedUserChanges.preferred_contact = []
        //     }else {
        //         updatedUserChanges.preferred_contact = ['email']
        //     };
    
        //     setUserChanges(updatedUserChanges);
        //     console.log(updatedUserChanges);
        // };
    };

    const storeUserChanges = async () => {
        console.log(userChanges);
        if (userChanges === undefined) {
            return;
        };

        try {
            await fetch(
              `https://corsavailable.herokuapp.com/https://realwealth-api-0787684ad674.herokuapp.com/profile/overview/${user.id}`, {
                method: "POST",
                headers: {
                  "auth": "TEST",
                  "Access-Control-Allow-Credentials": true,
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "GET,OPTIONS,PATCH,DELETE,POST,PUT",
                  "Access-Control-Allow-Headers":
                    "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version",
                  "Content-Type": "application/json",
                  "Origin": "",
                  "X-Requested-With": ""
                },
                body: JSON.stringify(userChanges)
              }).then(async (data) => {
                  const json = await data.json();
                  if (data.status === 200) {
                      const responseDataResult = json["result"];
                      if (responseDataResult !== undefined && responseDataResult !== '') {
                          router.refresh();
                      } else {
                          console.log('Failed to store courses');
                          showError('Authentication', 'Failed to save changes to your profile. Please try again later.');
                      }
                  } else {
                      const error = json['error'];
                      if (error !== undefined) {
                          showError('Authentication', 'Failed to save changes to your profile. Please try again later.')
                      } else {
                          showError('Authentication', 'Failed to save changes to your profile. Please try again later.')
                      };
                  };
              });
          } catch (error) {
              console.log(error);
              showError('Authentication', 'Failed to save changes to your profile. Please try again later.')
          };
    };

    useEffect(() => {
        setUserChanges(props);
    },[]);

    return(
        <VStack paddingX="50px" w="full" py="25px">
          <Stack
            w="full"
            bg="rgba(255, 255, 255, 1)"
            borderRadius="12px"
            px="32px"
            pt="32px"
          >
            <Stack>
              <Text
                fontFamily="'Roboto', sans-serif"
                fontSize="20px"
                textAlign="left"
                fontWeight="700"
                color="rgba(63, 66, 84, 1)"
              >
                {" "}
                Basic Info
              </Text>
              <Divider my="15px" />
            </Stack>
            <Stack w="full">
              <Box
                w="full"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                pb="14px"
              >
                <Box w="40%">
                  <Text
                    fontFamily="'Roboto', sans-serif"
                    fontSize="15px"
                    textAlign="left"
                    fontWeight="500"
                    color="rgba(63, 66, 84, 1)"
                  >
                    {" "}
                    Full Name
                  </Text>
                </Box>
                <Box
                  w="60%"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <FormControl w="50%" px="20px">
                    <Input
                    disabled
                      id="first_name"
                      name="email"
                      type="text"
                      variant="filled"
                      defaultValue={user && user.first_name ? user.first_name : ''}
                      bg="rgba(245, 248, 250, 1)"
                      borderRadius="6px"
                      height="48px"
                      fontFamily="'Roboto', sans-serif"
                      fontSize="15px"
                      textAlign="left"
                      color="rgba(94, 98, 120, 1)"
                      fontWeight="500"
                      _placeholder={{
                        fontFamily: "'Roboto', sans-serif",
                        fontSize: "15px",
                        textAlign: "left",
                        fontWeight: "500",
                        color: "rgba(94, 98, 120, 1)",
                      }}
                    />
                  </FormControl>
                  <FormControl w="50%" px="20px">
                    <Input
                    disabled
                      id="last_name"
                      name="email"
                      type="text"
                      variant="filled"
                      defaultValue={user !== undefined && user.last_name ? user.last_name : ''}
                      bg="rgba(245, 248, 250, 1)"
                      borderRadius="6px"
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box
                w="full"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                pb="14px"
              >
                <Box w="40%">
                  <Text
                    fontFamily="'Roboto', sans-serif"
                    fontSize="15px"
                    textAlign="left"
                    fontWeight="500"
                    color="rgba(63, 66, 84, 1)"
                  >
                    {" "}
                    Company
                  </Text>
                </Box>
                <Box
                  w="60%"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <FormControl w="100%" px="20px">
                    <Input
                      id="company"
                      name="fname"
                      type="text"
                      variant="filled"
                      onChange={handleChange}
                      defaultValue={props && props.company !== undefined ? props.company : ''}
                    //   value={userChanges.company || ''}
                      bg="rgba(245, 248, 250, 1)"
                      borderRadius="6px"
                      height="48px"
                      fontFamily="'Roboto', sans-serif"
                      fontSize="15px"
                      textAlign="left"
                      color="rgba(94, 98, 120, 1)"
                      fontWeight="500"
                      _placeholder={{
                        fontFamily: "'Roboto', sans-serif",
                        fontSize: "15px",
                        textAlign: "left",
                        fontWeight: "500",
                        color: "rgba(94, 98, 120, 1)",
                      }}
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box
                w="full"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                pb="14px"
              >
                <Box w="40%">
                  <Text
                    fontFamily="'Roboto', sans-serif"
                    fontSize="15px"
                    textAlign="left"
                    fontWeight="500"
                    color="rgba(63, 66, 84, 1)"
                  >
                    {" "}
                    Member ID
                  </Text>
                </Box>
                <Box
                  w="60%"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <FormControl w="100%" px="20px">
                    <Input
                    disabled
                      id="id"
                      name="DistributorID"
                      type="text"
                      variant="filled"
                      defaultValue={user && user.id ? user.id : ''}
                      bg="rgba(245, 248, 250, 1)"
                      borderRadius="6px"
                      height="48px"
                      fontFamily="'Roboto', sans-serif"
                      fontSize="15px"
                      textAlign="left"
                      color="rgba(94, 98, 120, 1)"
                      fontWeight="500"
                      _placeholder={{
                        fontFamily: "'Roboto', sans-serif",
                        fontSize: "15px",
                        textAlign: "left",
                        fontWeight: "500",
                        color: "rgba(94, 98, 120, 1)",
                      }}
                    />
                  </FormControl>
                </Box>
              </Box>

              <Box
                w="full"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                pb="14px"
              >
                <Box w="40%">
                  <Text
                    fontFamily="'Roboto', sans-serif"
                    fontSize="15px"
                    textAlign="left"
                    fontWeight="500"
                    color="rgba(63, 66, 84, 1)"
                  >
                    {" "}
                    Contact Phone
                  </Text>
                </Box>
                <Box
                  w="60%"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <FormControl w="100%" px="20px">
                    <Input
                      id="mobile"
                      name="ContactPhone"
                      type="text"
                      variant="filled"
                      onChange={handleChange}
                      defaultValue={props && props.mobile ? props.mobile : ''}
                      bg="rgba(245, 248, 250, 1)"
                      borderRadius="6px"
                      height="48px"
                      fontFamily="'Roboto', sans-serif"
                      fontSize="15px"
                      textAlign="left"
                      color="rgba(94, 98, 120, 1)"
                      fontWeight="500"
                      _placeholder={{
                        fontFamily: "'Roboto', sans-serif",
                        fontSize: "15px",
                        textAlign: "left",
                        fontWeight: "500",
                        color: "rgba(94, 98, 120, 1)",
                      }}
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box
                w="full"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                pb="14px"
              >
                <Box w="40%">
                  <Text
                    fontFamily="'Roboto', sans-serif"
                    fontSize="15px"
                    textAlign="left"
                    fontWeight="500"
                    color="rgba(63, 66, 84, 1)"
                  >
                    Company Site
                  </Text>
                </Box>
                <Box
                  w="60%"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <FormControl w="100%" px="20px">
                    <Input
                      id="company_site"
                      name="CompanySite"
                      type="text"
                      variant="filled"
                      onChange={handleChange}
                      defaultValue={props && props.company_site ? props.company_site : ''}
                      bg="rgba(245, 248, 250, 1)"
                      borderRadius="6px"
                      height="48px"
                      fontFamily="'Roboto', sans-serif"
                      fontSize="15px"
                      textAlign="left"
                      color="rgba(94, 98, 120, 1)"
                      fontWeight="500"
                      _placeholder={{
                        fontFamily: "'Roboto', sans-serif",
                        fontSize: "15px",
                        textAlign: "left",
                        fontWeight: "500",
                        color: "rgba(94, 98, 120, 1)",
                      }}
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box
                w="full"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                pb="14px"
              >
                <Box w="40%">
                  <Text
                    fontFamily="'Roboto', sans-serif"
                    fontSize="15px"
                    textAlign="left"
                    fontWeight="500"
                    color="rgba(63, 66, 84, 1)"
                  >
                    Country
                  </Text>
                </Box>
                <Box
                  w="60%"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <FormControl w="100%" px="20px" position="relative">
                    <Input
                      pl="50px"
                      id="country"
                      name="Country"
                      type="text"
                      variant="filled"
                      onChange={handleChange}
                      defaultValue={props && props.country ? props.country : ''}
                      bg="rgba(245, 248, 250, 1)"
                      borderRadius="6px"
                      height="48px"
                      fontFamily="'Roboto', sans-serif"
                      fontSize="15px"
                      textAlign="left"
                      color="rgba(94, 98, 120, 1)"
                      fontWeight="500"
                      _placeholder={{
                        fontFamily: "'Roboto', sans-serif",
                        fontSize: "15px",
                        textAlign: "left",
                        fontWeight: "500",
                        color: "rgba(94, 98, 120, 1)",
                      }}
                    />
                    <Box
                      position="absolute"
                      top="0"
                      left="25px"
                      h="full"
                      bottom="0"
                    >
                      <Button
                        bg="transparent"
                        p="0"
                        h="full"
                        _hover={{ bg: "transparent" }}
                      >
                        {" "}
                        <Image src={Img.us} alt="us" w="30px" />{" "}
                      </Button>
                    </Box>
                  </FormControl>
                </Box>
              </Box>
              <Box
                w="full"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                pb="14px"
              >
                <Box w="40%">
                  <Text
                    fontFamily="'Roboto', sans-serif"
                    fontSize="15px"
                    textAlign="left"
                    fontWeight="500"
                    color="rgba(63, 66, 84, 1)"
                  >
                    Time Zone
                  </Text>
                </Box>
                <Box
                  w="60%"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <FormControl w="100%" px="20px">
                    <Input
                      id="timezone"
                      name="timeZone"
                      type="text"
                      variant="filled"
                      onChange={handleChange}
                      defaultValue={props && props.timezone ? props.timezone : "(GMT-23:00) International Date Line West"} 
                      bg="rgba(245, 248, 250, 1)"
                      borderRadius="6px"
                      height="48px"
                      fontFamily="'Roboto', sans-serif"
                      fontSize="15px"
                      textAlign="left"
                      color="rgba(94, 98, 120, 1)"
                      fontWeight="500"
                      _placeholder={{
                        fontFamily: "'Roboto', sans-serif",
                        fontSize: "15px",
                        textAlign: "left",
                        fontWeight: "500",
                        color: "rgba(94, 98, 120, 1)",
                      }}
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box
                w="full"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                pb="14px"
              >
                <Box w="40%">
                  <Text
                    fontFamily="'Roboto', sans-serif"
                    fontSize="15px"
                    textAlign="left"
                    fontWeight="500"
                    color="rgba(63, 66, 84, 1)"
                  >
                    Preferred Contact Method
                  </Text>
                </Box>
                <Box
                  w="60%"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <FormControl w="100%" px="20px">
                    <Checkbox
                    id="email"
                    onChange={() => handleEmailChecked()}
                    defaultChecked={props && props.preferred_contact !== undefined && props.preferred_contact.includes('email') === true ? true : false}
                    // isChecked={userChanges && userChanges.preferred_contact !== undefined && userChanges.preferred_contact.includes('email') === true ? true : false}
                      fontFamily="'Roboto', sans-serif"
                      fontSize="14px"
                      textAlign="left"
                      fontWeight="500"
                      colorScheme="red"
                      size="lg"
                      color="rgba(94, 98, 120, 1)"
                      borderRadius="10px"
                    //   isChecked={true}
                    >
                      Email
                    </Checkbox>
                    <Checkbox
                    id="phone"
                    onChange={() => handlePhoneChecked()}
                    defaultChecked={props && props.preferred_contact !== undefined && props.preferred_contact.includes('phone') === true ? true : false}
                      fontFamily="'Roboto', sans-serif"
                      fontSize="14px"
                      textAlign="left"
                      fontWeight="500"
                      size="lg"
                      color="rgba(94, 98, 120, 1)"
                      borderRadius="10px"
                      mx="15px"
                      colorScheme="red"
                      defaultIsChecked
                    >
                      Phone
                    </Checkbox>
                  </FormControl>
                </Box>
              </Box>
              <Box
                w="full"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                pb="15px"
              >
                <Box w="40%">
                  <Text
                    fontFamily="'Roboto', sans-serif"
                    fontSize="15px"
                    textAlign="left"
                    fontWeight="500"
                    color="rgba(63, 66, 84, 1)"
                  >
                    Receive Email Blasts
                  </Text>
                </Box>
                <Box
                  w="60%"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <FormControl w="100%" px="20px">
                    <Switch isChecked={userChanges && userChanges.receive_emails !== undefined && userChanges.receive_emails === true ? true : false} size="lg" colorScheme="red" />
                  </FormControl>
                </Box>
              </Box>
              <Divider my="12px" />
              <Stack
                w="full"
                alignItems="center"
                flexDir="row"
                justifyContent="flex-end"
                pb="15px"
              >
                <Button isDisabled={userChanges && userChanges !== undefined ? false : true}>Discard</Button>
                <Button
                onClick={() => storeUserChanges()}
                  ml="15px"
                  colorScheme="red"
                  height="42px"
                  fontFamily="'Roboto', sans-serif"
                  fontSize="15px"
                  textAlign="left"
                  fontWeight="500"
                >
                  Save Changes
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </VStack>
    )
};

export default BasicInfo;