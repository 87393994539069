import { Box, Image, Link, Stack, VStack } from '@chakra-ui/react';
import React from 'react';
import { Img } from '../../../contents/content';

const Footer = () => {
    return (
        <VStack w="full" m="0" bg="#000001"  flexDir="row" justifyContent="flex-start" px="50px" h="100px">
            <Stack w="30%">
                <Image src={Img.logo} w="65px"/>
            </Stack>
            <Stack  w="70%" alignItems="flex-end">
                <Box display="flex" alignItems="center">
                    <Link  mx="10px" color="rgba(126, 130, 153, 1)">
                        Docs
                    </Link>                    
                    <Link mx="10px" color="rgba(126, 130, 153, 1)">
                    FAQ
                    </Link>                    
                    <Link mx="10px" color="rgba(126, 130, 153, 1)">
                    Terms of Service
                    </Link>                    
                    <Link mx="10px" color="rgba(126, 130, 153, 1)">
                        Privacy Policy
                    </Link>

                </Box>
            </Stack>
        </VStack>
    );
};

export default Footer;