import {
  Box,
  Button,
  Divider,
  HStack,
  Image,
  Link,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import TopBar from "../TopBar";
import { Icon, Img, upComingLesson } from "../../../contents/content";
import ReactPlayer from "react-player";
import { useParams } from "react-router-dom";
import { useRouter } from "../../../hooks/use-router";

const VideoPlayers = ({ userData }) => {

  const router = useRouter();
  const lessonId = useParams().lessonId;
  const playerRef = React.useRef();

  const [lessonData, setLessonData] = useState();
  const [upcomingLessons, setUpcomingLessons] = useState([]);
  const [isOpen, SetPlayer] = useState(false);

  const [postCount, setPostCount] = useState(0);

  const user = JSON.parse(localStorage.getItem('user'));

  const fetchLessonVideoData = async () => {
    try {
      await fetch(
        `https://corsavailable.herokuapp.com/https://realwealth-api-0787684ad674.herokuapp.com/education/lesson/${lessonId}`, {
          method: "GET",
          headers: {
            "auth": "TEST",
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,OPTIONS,PATCH,DELETE,POST,PUT",
            "Access-Control-Allow-Headers":
              "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version",
            "Content-Type": "application/json",
            "Origin": "",
            "X-Requested-With": ""
          }
        }).then(async (data) => {
            const json = await data.json();
            if (data.status === 200) {
                const responseDataResult = json["result"];
                if (responseDataResult !== undefined && responseDataResult !== '') {
                  setLessonData(responseDataResult);
                  fetchUpcomingLessons(responseDataResult);
                } else {
                    console.log('Failed to store lessons');
                    setLessonData({});
                }
            } else {
                const error = json['error'];
                if (error !== undefined) {
                    console.log('Failed to find lessons');
                    setLessonData({});
                } else {
                    console.log('Failed to find lessons');
                    setLessonData({});
                };
            };
        });
    } catch (error) {
        console.log(error);
    };
  };

  const fetchUpcomingLessons = async (currentLessonObj) => {
    try {
      await fetch(
        `https://corsavailable.herokuapp.com/https://realwealth-api-0787684ad674.herokuapp.com/education/lessons/${currentLessonObj.course}`, {
          method: "GET",
          headers: {
            "auth": "TEST",
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,OPTIONS,PATCH,DELETE,POST,PUT",
            "Access-Control-Allow-Headers":
              "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version",
            "Content-Type": "application/json",
            "Origin": "",
            "X-Requested-With": ""
          }
        }).then(async (data) => {
            const json = await data.json();
            if (data.status === 200) {
                const responseDataResult = json["result"];
                if (responseDataResult !== undefined && responseDataResult !== '') {
                  setUpcomingLessons(responseDataResult);
                } else {
                    console.log('Failed to store lessons');
                    setUpcomingLessons([]);
                }
            } else {
                const error = json['error'];
                if (error !== undefined) {
                    console.log('Failed to find lessons');
                    setUpcomingLessons([]);
                } else {
                    console.log('Failed to find lessons');
                    setUpcomingLessons([]);
                };
            };
        });
    } catch (error) {
        console.log(error);
    };
  };

  const fetchSenderForId = (sender) => {
    const usersObj = localStorage.getItem('users');
    if (usersObj !== undefined && usersObj !== null) {
      const users = JSON.parse(usersObj);
      const filtered = users.filter((user) => user.id === sender);
      if (filtered.length > 0) {
        return filtered[0].name ?? ''
      } else {
        return ''
      };
    } else {
      return ''
    };
  };

  const fetchSenderTitleForId = (sender) => {
    const usersObj = localStorage.getItem('users');
    if (usersObj !== undefined && usersObj !== null) {
      const users = JSON.parse(usersObj);
      const filtered = users.filter((user) => user.id === sender);
      if (filtered.length > 0) {
        return filtered[0].title ?? ''
      } else {
        return ''
      };
    } else {
      return ''
    };
  };

  const fetchSenderPhotoForId = (sender) => {
    const usersObj = localStorage.getItem('users');
    if (usersObj !== undefined && usersObj !== null) {
      const users = JSON.parse(usersObj);
      const filtered = users.filter((user) => user.id === sender);
      if (filtered.length > 0) {
        const targetUser = filtered[0]
        return targetUser.photo !== '' ? targetUser.photo : Img.avatarC3
      } else {
        return Img.avatarC3
      };
    } else {
      return Img.avatarC3
    };
  };

  const convertDurationToString = (d) => {
    // d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hr " : " hrs ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " m " : " ms ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " s" : " s") : "";
    return hDisplay + mDisplay + sDisplay; 
  };

  const selectedLesson = (selectedId) => {
    if (selectedId !== lessonId) {
      router.replace(`/education/${selectedId}`);
    router.refresh();
    }
  };

  const onDuration = (e) => {
    // console.log('Duration:');
    // console.log(e);
  };

  const onProgress = async (e) => {
    setPostCount(postCount + 1);

    if (postCount % 3 !== 0) {
      return;
    };

    try {
      await fetch(
        `https://corsavailable.herokuapp.com/https://realwealth-api-0787684ad674.herokuapp.com/education/progress/${user.id}`, {
        method: "POST",
        headers: {
          "auth": "TEST",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,OPTIONS,PATCH,DELETE,POST,PUT",
          "Access-Control-Allow-Headers":
            "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version",
          "Content-Type": "application/json",
          "Origin": "",
          "X-Requested-With": ""
        },
        body: JSON.stringify({
          lessonId: lessonId,
          timestamp: e.playedSeconds
        })
      }
      ).then(async (data) => {
        const json = await data.json();
        if (data.status === 200) {
          const responseDataResult = json["result"];
          if (responseDataResult !== undefined && responseDataResult !== '') {
            console.log(responseDataResult);
          }
        } else {
          const error = json['error'];
          if (error !== undefined) {
          } else {
          };
        };
      });
    } catch (error) {
    };
  };

  const onReady = useCallback(() => {
    if (userData && userData.current_lesson === lessonId) {
      const timeToStart = userData.current_watch_timestamp ?? 0;
      playerRef.current.seekTo(timeToStart, 'seconds');
    };
  },[playerRef.current]);

  useEffect(() => {
    fetchLessonVideoData();
  },[]);

  return (
    <div>
      <VStack bg="#f9fafb" w="full">
        <TopBar title={'Lesson Video'} subtitle={`id: ${lessonId}`} IsSearch="true" />
        <Stack px="50px" pt="20px" w="full">
          <HStack
            bg="rgba(255, 255, 255, 1)"
            borderRadius="12px"
            px="50px"
            pb="50px"
            pt={'35px'}
            mb="25px"
            w="full"
            alignItems="flex-start"
          >
            <Stack w="75%">
              <Box>
                <Text
                  fontFamily="'Poppins',sans-serif"
                  fontSize="23px"
                  fontWeight="600"
                  lineHeight="35px"
                  color="rgba(24, 28, 50, 1)"
                >
                  {lessonData && lessonData.title !== '' ? lessonData.title : ''}
                  {/* Wealth Academy 101 - Real Estate Mastery w/ Raphael Vargas */}
                </Text>
                <Divider my="20px" />
                {/* Uploader Info */}
                <Stack
                  cursor={'pointer'}
                  mt={'10px'}
                  w="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="start"
                  flexDir="row"
                  minW={'100%'}
                  gap={3}
                >
                  <Image src={lessonData && lessonData.uploader !== '' ? fetchSenderPhotoForId(lessonData.uploader) : ''} h={'34px'} w={'34px'} fit={'cover'} borderRadius={'17px'} alt="playerAvatar" my="10px" />
                  <VStack w={'auto'} align={'start'} gap={-3} >
                  <Text
                    fontFamily="'Poppins',sans-serif"
                    fontSize="13px"
                    fontWeight="600"
                    color="rgba(94, 98, 120, 1)"
                    noOfLines={1}
                    textAlign={'left'}
                    _hover={{textColor:'rgba(212, 32, 32, 1)'}}
                  >
                    {lessonData && lessonData.uploader !== '' ? fetchSenderForId(lessonData.uploader) : ''}
                  </Text>
                  <Text
                    fontFamily="'Poppins',sans-serif"
                    fontSize="12px"
                    fontWeight="500"
                    color="rgba(181, 181, 195, 1)"
                    textAlign={'left'}
                  >
                    {lessonData && lessonData.uploader !== '' ? fetchSenderTitleForId(lessonData.uploader) : ''}
                  </Text>
                  </VStack>
                </Stack>
              </Box>
              <Box py="20px">
                {isOpen ? (
                  <Box
                    w="full"
                    p="10px"
                    borderRadius={"15px"}
                    h={{ md: "341px", lg: "512px", xl: "512px" }}
                  >
                    <ReactPlayer
                    ref={playerRef}
                      url={lessonData.url}
                      onReady={onReady}
                      onDuration={onDuration}
                      onProgress={onProgress}
                      width="full"
                      height="500px"
                      controls
                      style={{ borderRadius: "25px", backgroundColor:'black'}}
                    />
                  </Box>
                ) : (
                  <Box position=" relative" w="full" borderRadius={'12px'}>
                    <Image
                      src={lessonData && lessonData.thumbnail ? lessonData.thumbnail : ''}
                      alt="edu1"
                      borderRadius="12px"
                      w="full"
                      h={{ md: "341px", lg: "500px", xl: "500px" }}
                      objectFit="cover"
                    />
                    <Box
                      position="absolute"
                      w="full"
                      h="full"
                      left="0"
                      top="0"
                      right="0"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Button
                        bg="rgba(0, 0, 0, 0.3)"
                        boxShadow="0px 0px 14px 0px rgba(0, 0, 0, 0.2)"
                        w="89.31px"
                        h="58.2px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        onClick={() => SetPlayer(!isOpen)}
                      >
                        <Image src={Icon.play} alt="play" />
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
              <Box py="15px">
                <Text
                  fontFamily="'Poppins',sans-serif"
                  fontSize="15px"
                  fontWeight="500"
                  lineHeight={'27px'}
                  color="rgba(126, 130, 153, 1)"
                >
                  {lessonData && lessonData.description !== '' ? lessonData.description : ''}
                </Text>
              </Box>
            </Stack>
            <Stack w="25%" pl="25px">
              <Box w="full">
                <Text
                  fontFamily="'Poppins',sans-serif"
                  fontSize="18px"
                  fontWeight="600"
                  color="rgba(0, 0, 0, 1)"
                >
                  Upcoming lessons
                </Text>
                <Stack pt="40px" w="full">
                  {
                    upcomingLessons.map((item,id)=>{
                      return(
                        <Stack key={id} alignItems="center" flexDir="row" justifyContent="flex-start" w="full" cursor={'pointer'} onClick={() => selectedLesson(item.id)} borderRadius={'12px'} backgroundColor={lessonId === item.id ? 'rgba(1, 1, 1, 0.05)' : ''}>
                        <Box width="100px" key={id} my="14px">
                          <Image
                            src={item.thumbnail}
                            alt="pic"
                            height="65px"
                            width="90px"
                            minW={'90px'}
                            maxH={'65px'}
                            borderRadius="8px"
                            fit={'cover'}
                            ml={lessonId === item.id ? '10px' : '0px'}
                          />
                        </Box>
                        <Box ml="8px"  w="full">
                          <Text
                          noOfLines={2}
                            mt={'-10px'}
                            fontFamily="'Poppins',sans-serif"
                            fontSize="15px"
                            fontWeight="600"
                            color="rgba(24, 28, 50, 1)"
                            mb="3px"
                          >
                            {item.title}
                          </Text>
                          <Text
                          noOfLines={1}
                            fontFamily="'Poppins',sans-serif"
                            fontSize="13px"
                            fontWeight="500"
                            color="rgba(126, 130, 153, 1)"
                          >
                            {convertDurationToString(item.duration ?? 0)}
                          </Text>
                        </Box>
                      </Stack>
                      ) 
                    })
                  }
                </Stack>
              </Box>
            </Stack>
          </HStack>
        </Stack>
      </VStack>
    </div>
  );
};

export default VideoPlayers;
