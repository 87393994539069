import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Layouts/Sidebar";
import { Stack, VStack } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import Footer from "../../components/Layouts/Footer";
import Loader from "./Loader";

const Home = () => {

  const [hasInitialized, setHasInitialized] = useState(false);

  
  const startFetch = async () => {
    try {
      await fetch(
        "https://corsavailable.herokuapp.com/https://realwealth-api-0787684ad674.herokuapp.com/startup/users", {
          method: "GET",
          headers: {
            "auth": "TEST",
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,OPTIONS,PATCH,DELETE,POST,PUT",
            "Access-Control-Allow-Headers":
              "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version",
            "Content-Type": "application/json",
            "Origin": "",
            "X-Requested-With": ""
          }
        }).then(async (data) => {
            const json = await data.json();
            if (data.status === 200) {
                const responseDataResult = json["result"];
                if (responseDataResult !== undefined && responseDataResult !== '') {
                    console.log(responseDataResult.length ?? 0);
                    localStorage.setItem('users', JSON.stringify(responseDataResult));
                    setHasInitialized(true);  // TODO: Change to true;
                } else {
                    console.log('Failed to store users')
                    setHasInitialized(true);  // TODO: Change to true;
                }
            } else {
                const error = json['error'];
                if (error !== undefined) {
                    console.log('Failed to find users');
                } else {
                    console.log('Failed to find users');
                };
            };
        });
    } catch (error) {
        console.log(error);
    };
  };

  useEffect(() => {
    startFetch();
  },[]);

  if (!hasInitialized) {
    return(
      <Loader/> 
    )
  }

  if (hasInitialized) {
    return (
      <div>
        <VStack flexDir="row" overflow={"hidden"} justifyContent="flex-start" alignItems="flex-start" gap="0">
          <Stack w="300px" >
            <Sidebar  />
          </Stack>
          <Stack w="100%" overflowX={"hidden"} overflowY="auto" h="100vh" gap="0" p="0">
            
            <Outlet></Outlet>
            <Stack gap="0" height="100px">
              <Footer/> 
            </Stack>
          </Stack>
        </VStack>
      </div>
    );
  };

};

export default Home;
