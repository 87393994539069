import { Box, Button, Divider, Stack, Text, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import TopBar from "../TopBar";
import { TransactionHistory } from "../../../contents/content";

const Transactions = () => {

  useEffect(() => {

  });

  return (
    <div>
      <VStack w="full" bg="rgba(249, 250, 251, 1)">
        <TopBar title="My Subscription" subtitle={'Manage your transactions and payouts here.'} />
        {/* Transactions Top */}
        <Stack w="full" px="50px" pt="20px">
          <Stack
            bg="rgba(255, 255, 255, 1)"
            borderRadius="12px"
            p="30px"
            mb="25px"
          >
            <Box>
              <Text
                color="rgba(63, 66, 84, 1)"
                fontFamily="'Roboto',sans-serif"
                fontSize="20px"
                fontWeight="700"
              >
                Earnings
              </Text>
            </Box>
            <Divider my="15px" />
            <Box mb="15px">
              <Text
                color="rgba(126, 130, 153, 1)"
                fontFamily="'Roboto',sans-serif"
                fontSize="16px"
                fontWeight="500"
              >
                Last 30 day earnings calculated. Apart from arranging the order
                of topics.
              </Text>
            </Box>
            <Stack flexDir="row" justifyContent="space-between">
              <Stack flexDir="row">
                <Box
                  border=" 1px dashed rgba(228, 230, 239, 1)"
                  h="70px"
                  w="150px"
                  px="12px"
                  py="4px"
                  mr="14px"
                >
                  <Text
                    fontFamily="'Roboto', sans-serif"
                    fontSize="24px"
                    textAlign="left"
                    fontWeight="900"
                    color="rgba(63, 66, 84, 1)"
                  >
                    N/A
                  </Text>
                  <Text
                    fontFamily="'Roboto', sans-serif"
                    fontSize="14px"
                    textAlign="left"
                    fontWeight="500"
                    color="rgba(181, 181, 195, 1)"
                  >
                    Rank
                  </Text>
                </Box>
                <Box
                  border=" 1px dashed rgba(228, 230, 239, 1)"
                  h="70px"
                  w="150px"
                  px="12px"
                  py="4px"
                  mr="14px"
                >
                  <Text
                    fontFamily="'Roboto', sans-serif"
                    fontSize="24px"
                    textAlign="left"
                    fontWeight="900"
                    color="rgba(63, 66, 84, 1)"
                  >
                    $0.00
                  </Text>
                  <Text
                    fontFamily="'Roboto', sans-serif"
                    fontSize="14px"
                    textAlign="left"
                    fontWeight="500"
                    color="rgba(181, 181, 195, 1)"
                  >
                    Current
                  </Text>
                </Box>
                <Box
                  border=" 1px dashed rgba(228, 230, 239, 1)"
                  h="70px"
                  w="150px"
                  px="12px"
                  py="4px"
                  mr="14px"
                >
                  <Text
                    fontFamily="'Roboto', sans-serif"
                    fontSize="24px"
                    textAlign="left"
                    fontWeight="900"
                    color="rgba(63, 66, 84, 1)"
                  >
                    $0.00
                  </Text>
                  <Text
                    fontFamily="'Roboto', sans-serif"
                    fontSize="14px"
                    textAlign="left"
                    fontWeight="500"
                    color="rgba(181, 181, 195, 1)"
                  >
                    Pending
                  </Text>
                </Box>
              </Stack>
              <Stack alignItems="flex-end" justifyContent="flex-end">
                <Button
                  bg="rgba(80, 205, 137, 1)"
                  h="42px"
                  _hover={{ bg: "rgba(80, 205, 137, 1)" }}
                  fontFamily="'Roboto', sans-serif"
                  fontSize="15px"
                  fontWeight="500"
                  textAlign="center"
                  color="rgba(255, 255, 255, 1)"
                >
                  Customer Portal
                </Button>
              </Stack>
            </Stack>
          </Stack>

          {/* Transaction History */}
          <Stack
            bg="rgba(255, 255, 255, 1)"
            borderRadius="12px"
            mb="25px"
            gap="0"
          >
            <Stack flexDir="row" justifyContent="space-between" px="30px" pt="20px" gap="0">
              <Box>
                <Text
                  color="rgba(63, 66, 84, 1)"
                  fontFamily="'Roboto',sans-serif"
                  fontSize="20px"
                  fontWeight="700"
                >
                  Transaction History
                </Text>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Button
                  variant="link"
                  color="rgba(63, 66, 84, 1)"
                  fontFamily="'Roboto',sans-serif"
                  fontSize="16px"
                  fontWeight="500"
                  mr="14px"
                >
                  This Year
                </Button>
                <Button
                  variant="link"
                  color="rgba(181, 181, 195, 1)"
                  fontFamily="'Roboto',sans-serif"
                  fontSize="16px"
                  fontWeight="500"
                  mr="14px"
                >
                  2022
                </Button>
                <Button
                  variant="link"
                  color="rgba(181, 181, 195, 1)"
                  fontFamily="'Roboto',sans-serif"
                  fontSize="16px"
                  fontWeight="500"
                  mr="14px"
                >
                  2021
                </Button>
                <Button
                  variant="link"
                  color="rgba(181, 181, 195, 1)"
                  fontFamily="'Roboto',sans-serif"
                  fontSize="16px"
                  fontWeight="500"
                >
                  2020
                </Button>
              </Box>
            </Stack>
            <Divider mt="15px" />
            <Stack flexDir="row" bg="rgba(245, 248, 250, 1)" borderBottom="1px solid rgba(243, 246, 249, 1)" pb="15px" pt="15px" gap="0" px="30px">
              <Box w="20%">
                <Text
                  color="rgba(63, 66, 84, 1)"
                  fontFamily="'Roboto',sans-serif"
                  fontSize="16px"
                  fontWeight="500"
                >
                  Date
                </Text>
              </Box>
              <Box w="20%" textAlign="center">
                <Text
                  color="rgba(63, 66, 84, 1)"
                  fontFamily="'Roboto',sans-serif"
                  fontSize="16px"
                  fontWeight="500"
                >
                  Order ID
                </Text>
              </Box>
              <Box w="30%" textAlign="left">
                <Text
                  color="rgba(63, 66, 84, 1)"
                  fontFamily="'Roboto',sans-serif"
                  fontSize="16px"
                  fontWeight="500"
                >
                  Details
                </Text>
              </Box>
              <Box w="15%" textAlign="center">
                <Text
                  color="rgba(63, 66, 84, 1)"
                  fontFamily="'Roboto',sans-serif"
                  fontSize="16px"
                  fontWeight="500"
                >
                  Amount
                </Text>
              </Box>
              <Box w="15%" textAlign="right">
                <Text
                  color="rgba(63, 66, 84, 1)"
                  fontFamily="'Roboto',sans-serif"
                  fontSize="16px"
                  fontWeight="500"
                >
                  Invoice
                </Text>
              </Box>
            </Stack>
            <Stack  px="30px">
              {TransactionHistory.map((item, index) => (
                <Stack flexDir="row" alignItems="center" pb="15px" pt="15px" borderBottom="1px solid rgba(243, 246, 249, 1)" key={index}>
                  <Box w="20%">
                    <Text
                      color="rgba(63, 66, 84, 1)"
                      fontFamily="'Roboto',sans-serif"
                      fontSize="16px"
                      fontWeight="500"
                    >
                      {item.date}
                    </Text>
                  </Box>
                  <Box w="20%" textAlign="center">
                    <Text
                      color="rgba(63, 66, 84, 1)"
                      fontFamily="'Roboto',sans-serif"
                      fontSize="16px"
                      fontWeight="500"
                    >
                      {item.orderID}
                    </Text>
                  </Box>
                  <Box w="30%" textAlign="left">
                    <Text
                      color="rgba(63, 66, 84, 1)"
                      fontFamily="'Roboto',sans-serif"
                      fontSize="16px"
                      fontWeight="500"
                    >
                      {item.details}
                    </Text>
                  </Box>
                  <Box w="15%" textAlign="center">
                    <Text
                      color="rgba(80, 205, 137, 1)"
                      fontFamily="'Roboto',sans-serif"
                      fontSize="16px"
                      fontWeight="500"

                    >
                      {item.amount}
                    </Text>
                  </Box>
                  <Box w="15%" textAlign="right">
                    <Button
                    color="rgba(161, 165, 183, 1)"
                    fontFamily="'Roboto',sans-serif"
                    fontSize="12px"
                    fontWeight="500"
                    bg="rgba(245, 248, 250, 1)"
                    >
                    Download
                    </Button>
                    <Text

                    >
                      
                    </Text>
                  </Box>
                </Stack>
              ))}
            </Stack>

          </Stack>
          {/* <Stack mb="40px">
                <Button colorScheme="red" 
                fontFamily="'Roboto',sans-serif"
                fontSize="15px"
                fontWeight="500"
                >
                    Load more
                </Button>
            </Stack> */}
        </Stack>
      </VStack>
    </div>
  );
};

export default Transactions;
