import React, { useEffect } from "react";
import { Box, Button, Image, Stack, Text, VStack } from "@chakra-ui/react";
import { Icon, Img, SideMenu } from "../../../contents/content";
import { NavLink } from "react-router-dom";
import "./Sidebar.css";
import { useRouter } from "../../../hooks/use-router";
const Sidebar = () => {

  const router = useRouter();

  const user = JSON.parse(localStorage.getItem('user'));

  const logout = () => {
    localStorage.removeItem('user');
    localStorage.clear()
    router.push('/login');
  };

  const convertTimestampToDateString = (timestampString) => {
    const date = new Date(timestampString);
    // Format date to "MMM dd, yyyy HH:mm"
    const options = {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    const formattedDate = date.toLocaleString("en-US", options);
    return formattedDate;
  };

  const fetchSenderForId = (sender) => {
    const usersObj = localStorage.getItem('users');
    if (usersObj !== undefined && usersObj !== null) {
      const users = JSON.parse(usersObj);
      const filtered = users.filter((user) => user.id === sender);
      if (filtered.length > 0) {
        return filtered[0].name ?? ''
      } else {
        return ''
      };
    } else {
      return ''
    };
  };

  const fetchSenderPhotoForId = (sender) => {
    const usersObj = localStorage.getItem('users');
    if (usersObj !== undefined && usersObj !== null) {
      const users = JSON.parse(usersObj);
      const filtered = users.filter((user) => user.id === sender);
      if (filtered.length > 0) {
        const targetUser = filtered[0]
        return targetUser.photo !== '' ? targetUser.photo : Img.avatarC3
      } else {
        return Img.avatarC3
      };
    } else {
      return Img.avatarC3
    };
  };

  useEffect(() => {

  },[]);

  return (
    <VStack className="" w="100%" minH="100vh" maxH="100%" bg="#000001" justifyContent="space-between">
      <Box w="full">
      <Stack
        py="50px"
        textAlign="center"
        display="flex"
        alignItems="center"
        justifyContent="center"
        w="full"
      >
        {/* logo */}
        <img src={Img.logo} alt="logo" height={'80px'} style={{maxHeight:'80px'}}/>
      </Stack>
      <Stack 
      pl={'30px'}
      pr={'30px'}
      display="flex"
      alignItems="center"
      justifyContent="center"
      w={'full'}>
        <Button isDisabled={false} _disabled={{borderColor:'rgba(255, 255, 255, 0.85)', opacity:'0.35'}}  borderColor={'white'} borderWidth={'2.5px'} backgroundColor={'black'} w={'100%'} h={'46px'} borderRadius={'23px'} mb={'42px'} _hover={{backgroundColor:'rgba(212, 32, 32, 1)', borderWidth:'0px'}}>
          <Image h='16px' mr={'15px'} src={Icon.play}/>
        <Text
          fontFamily="'Poppins', sans-serif"
          fontWeight="600"
          fontSize="15px"
          textColor={'white'}
        >
          Join Live Call
          </Text>
        </Button>
      </Stack>
      <Stack alignItems="flex-start" w="full">
        {SideMenu.map((item, id) => {
          return (
            <Box
              alignItems="flex-start"
              key={id}
              mb="18px"
              className="menu"
              width="185px"
              mx="auto"
            >
              <NavLink
                to={item.url}
                className={({ isActive, isPending }) =>
                  isPending
                    ? "pending"
                    : isActive
                    ? "active nav-item"
                    : "nav-item"
                }
              >
                <Text display="flex" alignItems="center">
                  <Image src={item.icon} alt={item.name} mr="10px" />
                  <Text
                    fontFamily="'Poppins', sans-serif"
                    fontWeight="500"
                    fontSize="15px"
                  >
                    {item.name}
                  </Text>
                </Text>
                {item.subMenu ? (
                  <Stack
                    alignItems="flex-start"
                    w="full"
                    pl="20px"
                    pt="13px"
                    className="nav-drop"
                  >
                    {item.subMenu.map((items) => (
                      <Box alignItems="flex-start" w="full" key={id}>
                        <NavLink to={items.url}>
                          <Text display="flex" alignItems="center">
                            <Text color="rgba(130, 130, 130, 1)">
                              {items.name}
                            </Text>
                          </Text>
                        </NavLink>
                      </Box>
                    ))}
                  </Stack>
                ) : (
                  ""
                )}
              </NavLink>
            </Box>
          );
        })}
      </Stack>
      </Box>
      <Box w={'100%'}>
          <Box width={'100%'} left= '0' display="flex" alignItems="start" px="24px" cursor={'pointer'}>
                <Image src={fetchSenderPhotoForId(user.id ?? '')} borderRadius={'12px'} alt="avatar3" w="42px" h='42px' mr="9px" />
                <Box>
                  <Text
                    fontFamily="'Poppins', sans-serif"
                    fontSize="15px"
                    fontWeight="700"
                    color="rgba(255, 255, 255, 1)"
                    width={'100%'}
                  >
                    {user !== null && user.name !== '' ? user.name : 'User'}
                  </Text>
                  <Text
                    fontFamily="'Poppins', sans-serif"
                    fontSize="12px"
                    fontWeight="500"
                    color="rgba(126, 130, 153, 1)"
                    width={'80%'}
                    noOfLines={1}
                  >
                    {user !== null && user.email !== '' ? user.email : 'Real Wealth User'}
                    {/* Joined Dec 22, 2021 */}
                  </Text>
                </Box>
          </Box>

          <Box w="full" height="100%" display="flex" alignItems="center" justifyContent="flex-start" px="24px" py="25px">
            <Box w="80%" >
              <Button bg="transparent" display="flex" alignItems="center" p="0" gap={'8px'} _hover={{bg:"transparent"}} color="rgba(218, 218, 218, 1)" onClick={() => logout()}>
                <Image src={Icon.logout} />
                Log Out
              </Button>
            </Box>
            <Box w="20%">
              {/* <Button  bg="transparent" display="flex" alignItems="center" p="0" _hover={{bg:"transparent"}}>
                <Image src={Icon.darkMode} />
              </Button> */}
            </Box>
          </Box>
      </Box>
    </VStack>
  );
};

export default Sidebar;
