import { Box, Button, Image, Stack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Icon } from "../../../contents/content";

const TopBar = ({title,subtitle,}) => {
  return (
    <VStack
      height="108px"
      bg="rgba(249, 250, 251, 1)"
      paddingX="50px"
      width="100%"
      justifyContent="flex-end"
      pb="10px"
    >
      <Stack flexDir="row" w="full" alignItems="flex-start">
        <Box w="50%">
          <Text
            fontFamily="'Poppins', sans-serif"
            fontSize="24px"
            fontWeight="600"
            lineHeight="28px"
            textAlign="left"
            color="rgba(24, 28, 50, 1)"
          >
            {title}
          </Text>
          <Text
            fontFamily="'Poppins', sans-serif"
            fontSize="15px"
            fontWeight="500"
            lineHeight="28px"
            textAlign="left"
            color="rgba(181, 181, 195, 1)"
          >
             {subtitle}
          </Text>
        </Box>
        <Box w="50%" textAlign="right">
          <Button borderRadius="10px" border="1px solid rgba(228, 230, 239, 1)" bg="transparent" h="40px" w="40px" p="0">
            <Image src={Icon.notification} alt="notification"/>
          </Button>
        </Box>
      </Stack>
    </VStack>
  );
};

export default TopBar;
