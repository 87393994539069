import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    Image,
    Input,
    Link,
    Progress,
    Stack,
    Switch,
    Text,
    VStack,
  } from "@chakra-ui/react";
  import React, { useEffect, useState } from "react";
  import { Icon, Img } from "../../../../../../contents/content";

const Banner = ({ props, showError, changeProfilePhoto }) => {

    const user = JSON.parse(localStorage.getItem('user'));

    const [subscriptionData, setSubscriptionData] = useState();

    const fetchSubscription = async () => {
        if (user.subscription_id === undefined) {
            return;
        };

        try {
            await fetch(
              `https://corsavailable.herokuapp.com/https://realwealth-api-0787684ad674.herokuapp.com/auth/subscription`, {
              method: "POST",
              headers: {
                "auth": "TEST",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,OPTIONS,PATCH,DELETE,POST,PUT",
                "Access-Control-Allow-Headers":
                  "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version",
                "Content-Type": "application/json",
                "Origin": "",
                "X-Requested-With": ""
              },
              body: JSON.stringify({
                subscriptionId: user.subscription_id
              })
            }
            ).then(async (data) => {
              const json = await data.json();
              if (data.status === 200) {
                const responseDataResult = json["result"];
                if (responseDataResult !== undefined && responseDataResult !== '') {
                    setSubscriptionData(responseDataResult);
                }
              } else {
                const error = json['error'];
                if (error !== undefined) {
                  showError('Authentication', error);
                } else {
                  showError('Authentication', 'Failed to login. Please try again later');
                };
              };
            });
          } catch (error) {
            console.log(error)
        };
    };

    const convertTimestampToDateString = (timestampString) => {
        const date = new Date(timestampString);
        const options = {
          month: "short",
          day: "numeric",
        };
        const formattedDate = date.toLocaleString("en-US", options);
        return formattedDate;
    };

    useState(() => {
        fetchSubscription();
    },[]);

    return(
        <VStack paddingX="50px" w="full" py="25px">
          <Stack
            w="full"
            bg="rgba(255, 255, 255, 1)"
            borderRadius="12px"
            px="32px"
            pt="32px"
          >
            <Stack flexDir="row" w="100%">
              <Box width="70%" h="100%">
                <Stack flexDir="row">
                  <Box position="relative" display="inline-block">
                    <Image src={user.photo ?? ''} alt="" h="160px" w="160px" minWidth={'160px'} borderRadius={'14px'} fit={'cover'}/>
                    <Box
                      position="absolute"
                      height="20px"
                      width="20px"
                      right="-8px"
                      bottom="25px"
                      borderRadius="25px"
                      bg="rgba(80, 205, 137, 1)"
                    ></Box>
                  </Box>

                  <Stack justifyContent="space-between">
                    <Stack>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                        pl="25px"
                      >
                        <Text
                          fontFamily="'Roboto', sans-serif"
                          fontSize="19px"
                          textAlign="left"
                          fontWeight="700"
                          color="rgba(63, 66, 84, 1)"
                        >
                          {user.name ?? ''}
                        </Text>
                        <Image src={Icon.verified} alt="" mx="5px" />
                      </Box>
                      <Stack pl="25px" flexDir="row">
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-start"
                          mr="12px"
                        >
                          <Image src={Icon.user} alt="user" />
                          <Text
                            fontFamily="'Roboto', sans-serif"
                            fontSize="14px"
                            textAlign="left"
                            fontWeight="500"
                            color="rgba(181, 181, 195, 1)"
                            mx="5px"
                          >
                            {user.title ?? ''}
                          </Text>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-start"
                          mr="12px"
                        >
                          <Image src={Icon.location} alt="location" />
                          <Text
                            fontFamily="'Roboto', sans-serif"
                            fontSize="14px"
                            textAlign="left"
                            fontWeight="500"
                            color="rgba(181, 181, 195, 1)"
                            mx="5px"
                          >
                            {user.location ?? ''}
                          </Text>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-start"
                          mr="12px"
                        >
                          <Image src={Icon.search} alt="search" />
                          <Text
                            fontFamily="'Roboto', sans-serif"
                            fontSize="14px"
                            textAlign="left"
                            fontWeight="500"
                            color="rgba(181, 181, 195, 1)"
                            mx="5px"
                          >
                            {user.email}
                          </Text>
                        </Box>
                      </Stack>
                    </Stack>
                    <Stack pl="25px" flexDir="row">
                      <Box
                        border=" 1px dashed rgba(228, 230, 239, 1)"
                        h="70px"
                        w="150px"
                        px="12px"
                        py="4px"
                        mr="14px"
                      >
                        <Text
                          fontFamily="'Roboto', sans-serif"
                          fontSize="24px"
                          textAlign="left"
                          fontWeight="900"
                          color="rgba(63, 66, 84, 1)"
                        >
                          {0}
                        </Text>
                        <Text
                          fontFamily="'Roboto', sans-serif"
                          fontSize="14px"
                          textAlign="left"
                          fontWeight="500"
                          color="rgba(181, 181, 195, 1)"
                        >
                          Courses Complete
                        </Text>
                      </Box>
                      <Box
                        border=" 1px dashed rgba(228, 230, 239, 1)"
                        h="70px"
                        w="150px"
                        px="12px"
                        py="4px"
                        mr="14px"
                      >
                        <Text
                          fontFamily="'Roboto', sans-serif"
                          fontSize="24px"
                          textAlign="left"
                          fontWeight="900"
                          color="rgba(63, 66, 84, 1)"
                        >
                          {subscriptionData && convertTimestampToDateString(subscriptionData.current_period_end)}
                        </Text>
                        <Text
                          fontFamily="'Roboto', sans-serif"
                          fontSize="14px"
                          textAlign="left"
                          fontWeight="500"
                          color="rgba(181, 181, 195, 1)"
                        >
                          Renewal Date
                        </Text>
                      </Box>
                      <Box
                        border=" 1px dashed rgba(228, 230, 239, 1)"
                        h="70px"
                        w="150px"
                        px="12px"
                        py="4px"
                        mr="14px"
                      >
                        <Text
                          fontFamily="'Roboto', sans-serif"
                          fontSize="24px"
                          textAlign="left"
                          fontWeight="900"
                          color="rgba(63, 66, 84, 1)"
                        >
                          N/A
                        </Text>
                        <Text
                          fontFamily="'Roboto', sans-serif"
                          fontSize="14px"
                          textAlign="left"
                          fontWeight="500"
                          color="rgba(181, 181, 195, 1)"
                        >
                          Rank
                        </Text>
                      </Box>
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
              <Box width="30%" h="100%">
                <Stack
                  justifyContent="space-between"
                  w="full"
                  alignItems="flex-start"
                  h="100%"
                >
                  <Stack
                    w="full"
                    flexDir="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    h="100%"
                  >
                    <Box>
                      <Button
                        bg="rgba(245, 248, 250, 1)"
                        _hover={{ bg: "rgba(245, 248, 250, 1)" }}
                        fontFamily="'Roboto', sans-serif"
                        fontSize="15px"
                        fontWeight="700"
                        color="rgba(126, 130, 153, 1)"
                        mr="15px"
                        borderRadius="6px"
                        height="50px"
                      >
                        Help
                      </Button>
                      <Button
                        bg="rgba(242, 63, 68, 1)"
                        _hover={{ bg: "rgba(242, 63, 68, 1)" }}
                        fontFamily="'Roboto', sans-serif"
                        fontSize="15px"
                        fontWeight="700"
                        color="rgba(255, 255, 255, 1)"
                        borderRadius="6px"
                        height="50px"
                      >
                        Change Profile Image
                      </Button>
                    </Box>
                  </Stack>
                  <Stack w="full" pt="30px">
                    <Box
                      display=" flex"
                      justifyContent="space-between"
                      alignItems="center"
                      w="full"
                    >
                      <Text
                        fontFamily="'Roboto', sans-serif"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(126, 130, 153, 1)"
                      >
                        Profile Status
                      </Text>
                      <Text
                        fontFamily="'Roboto', sans-serif"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(126, 130, 153, 1)"
                      >
                        68%
                      </Text>
                    </Box>
                    <Box>
                      <Progress
                        value={68}
                        size="xs"
                        height="10px"
                        colorScheme="red"
                        borderRadius="15px"
                      />
                    </Box>
                  </Stack>
                </Stack>
              </Box>
            </Stack>

            <Stack
              borderTop="1px solid rgba(239, 242, 245, 1)"
              w="full"
              mt="20px"
              pt="20px"
            >
              <Box display="flex" justifyContent="flex-start">
                <Button
                  borderBottom="2px solid rgba(242, 63, 68, 1)"
                  bg="transparent"
                  borderRadius="0"
                  paddingBottom="15px"
                  _hover={{ bg: "transparent" }}
                  fontFamily="'Roboto', sans-serif"
                  fontSize="16px"
                  textAlign="left"
                  fontWeight="500"
                  color="rgba(242, 63, 68, 1)"
                >
                  Overview
                </Button>
                <Button
                  borderBottom="2px solid #fff"
                  bg="transparent"
                  borderRadius="0"
                  paddingBottom="15px"
                  _hover={{
                    bg: "transparent",
                    borderColor: "rgba(242, 63, 68, 1)",
                    transition: "0.3s",
                  }}
                  fontFamily="'Roboto', sans-serif"
                  fontSize="16px"
                  textAlign="left"
                  fontWeight="500"
                  color="rgba(161, 165, 183, 1)"
                >
                  Billing Info
                </Button>
                {/* <Button
                  borderBottom="2px solid #fff"
                  bg="transparent"
                  borderRadius="0"
                  paddingBottom="15px"
                  _hover={{
                    bg: "transparent",
                    borderColor: "rgba(242, 63, 68, 1)",
                    transition: "0.3s",
                  }}
                  fontFamily="'Roboto', sans-serif"
                  fontSize="16px"
                  textAlign="left"
                  fontWeight="500"
                  color="rgba(161, 165, 183, 1)"
                >
                  Documents
                </Button> */}
                <Button
                  borderBottom="2px solid #fff"
                  bg="transparent"
                  borderRadius="0"
                  paddingBottom="15px"
                  _hover={{
                    bg: "transparent",
                    borderColor: "rgba(242, 63, 68, 1)",
                    transition: "0.3s",
                  }}
                  fontFamily="'Roboto', sans-serif"
                  fontSize="16px"
                  textAlign="left"
                  fontWeight="500"
                  color="rgba(161, 165, 183, 1)"
                >
                  Activity
                </Button>
              </Box>
            </Stack>
          </Stack>
        </VStack>
    )
};

export default Banner;