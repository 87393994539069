import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    Image,
    Input,
    Link,
    Progress,
    Stack,
    Switch,
    Text,
    VStack,
  } from "@chakra-ui/react";
  import React, { useEffect, useState } from "react";
  import { Icon, Img } from "../../../../../../contents/content";

const Notifications = ({props}) => {

    return(
        <VStack paddingX="50px" w="full" py="25px">
          <Stack
            w="full"
            bg="rgba(255, 255, 255, 1)"
            borderRadius="12px"
            px="32px"
            pt="32px"
          >
            <Box>
              <Text
                fontFamily="'Roboto', sans-serif"
                fontSize="20px"
                textAlign="left"
                fontWeight="700"
                color="rgba(63, 66, 84, 1)"
              >
                Notifications
              </Text>
            </Box>
            <Box
              w="full"
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Box w="40%">
                <Text
                  fontFamily="'Roboto', sans-serif"
                  fontSize="15px"
                  textAlign="left"
                  fontWeight="500"
                  color="rgba(63, 66, 84, 1)"
                >
                  Notifications
                </Text>
              </Box>
              <Box
                w="60%"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                borderTop="1px solid rgba(239, 242, 245, 1)"
                paddingY="26px"
              >
                <FormControl
                  w="100%"
                  px="20px"
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Checkbox
                    fontFamily="'Roboto', sans-serif"
                    fontSize="14px"
                    textAlign="left"
                    fontWeight="500"
                    colorScheme="red"
                    size="lg"
                    color="rgba(94, 98, 120, 1)"
                    borderRadius="10px"
                    mr="15px"
                    w="100px"
                  >
                    Email
                  </Checkbox>
                  <Checkbox
                    fontFamily="'Roboto', sans-serif"
                    fontSize="14px"
                    textAlign="left"
                    fontWeight="500"
                    size="lg"
                    color="rgba(94, 98, 120, 1)"
                    borderRadius="10px"
                    mx="15px"
                    colorScheme="red"
                    defaultIsChecked
                    w="100px"
                  >
                    Phone
                  </Checkbox>
                </FormControl>
              </Box>
            </Box>
            <Box
              w="full"
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Box w="40%">
                <Text
                  fontFamily="'Roboto', sans-serif"
                  fontSize="15px"
                  textAlign="left"
                  fontWeight="500"
                  color="rgba(63, 66, 84, 1)"
                >
                  Billing Updates
                </Text>
              </Box>
              <Box
                w="60%"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                borderTop="1px solid rgba(239, 242, 245, 1)"
                paddingY="26px"
              >
                <FormControl
                  w="100%"
                  px="20px"
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Checkbox
                    fontFamily="'Roboto', sans-serif"
                    fontSize="14px"
                    textAlign="left"
                    fontWeight="500"
                    colorScheme="red"
                    size="lg"
                    color="rgba(94, 98, 120, 1)"
                    borderRadius="10px"
                    mr="15px"
                    w="100px"
                  ></Checkbox>
                  <Checkbox
                    fontFamily="'Roboto', sans-serif"
                    fontSize="14px"
                    textAlign="left"
                    fontWeight="500"
                    size="lg"
                    color="rgba(94, 98, 120, 1)"
                    borderRadius="10px"
                    mx="15px"
                    colorScheme="red"
                    defaultIsChecked
                    w="100px"
                  ></Checkbox>
                </FormControl>
              </Box>
            </Box>

            <Box
              w="full"
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Box w="40%">
                <Text
                  fontFamily="'Roboto', sans-serif"
                  fontSize="15px"
                  textAlign="left"
                  fontWeight="500"
                  color="rgba(63, 66, 84, 1)"
                >
                  New Team Members
                </Text>
              </Box>
              <Box
                w="60%"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                borderTop="1px solid rgba(239, 242, 245, 1)"
                paddingY="26px"
              >
                <FormControl
                  w="100%"
                  px="20px"
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Checkbox
                    fontFamily="'Roboto', sans-serif"
                    fontSize="14px"
                    textAlign="left"
                    fontWeight="500"
                    colorScheme="red"
                    size="lg"
                    color="rgba(94, 98, 120, 1)"
                    borderRadius="10px"
                    mr="15px"
                    w="100px"
                  ></Checkbox>
                  <Checkbox
                    fontFamily="'Roboto', sans-serif"
                    fontSize="14px"
                    textAlign="left"
                    fontWeight="500"
                    size="lg"
                    color="rgba(94, 98, 120, 1)"
                    borderRadius="10px"
                    mx="15px"
                    colorScheme="red"
                    defaultIsChecked
                    w="100px"
                  ></Checkbox>
                </FormControl>
              </Box>
            </Box>

            <Box
              w="full"
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Box w="40%">
                <Text
                  fontFamily="'Roboto', sans-serif"
                  fontSize="15px"
                  textAlign="left"
                  fontWeight="500"
                  color="rgba(63, 66, 84, 1)"
                >
                  Completed Projects
                </Text>
              </Box>
              <Box
                w="60%"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                borderTop="1px solid rgba(239, 242, 245, 1)"
                paddingY="26px"
              >
                <FormControl
                  w="100%"
                  px="20px"
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Checkbox
                    fontFamily="'Roboto', sans-serif"
                    fontSize="14px"
                    textAlign="left"
                    fontWeight="500"
                    colorScheme="red"
                    size="lg"
                    color="rgba(94, 98, 120, 1)"
                    borderRadius="10px"
                    mr="15px"
                    w="100px"
                  ></Checkbox>
                  <Checkbox
                    fontFamily="'Roboto', sans-serif"
                    fontSize="14px"
                    textAlign="left"
                    fontWeight="500"
                    size="lg"
                    color="rgba(94, 98, 120, 1)"
                    borderRadius="10px"
                    mx="15px"
                    colorScheme="red"
                    defaultIsChecked
                    w="100px"
                  ></Checkbox>
                </FormControl>
              </Box>
            </Box>
            <Box
              w="full"
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Box w="40%">
                <Text
                  fontFamily="'Roboto', sans-serif"
                  fontSize="15px"
                  textAlign="left"
                  fontWeight="500"
                  color="rgba(63, 66, 84, 1)"
                >
                  Newsletters
                </Text>
              </Box>
              <Box
                w="60%"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                borderTop="1px solid rgba(239, 242, 245, 1)"
                paddingY="26px"
              >
                <FormControl
                  w="100%"
                  px="20px"
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Checkbox
                    fontFamily="'Roboto', sans-serif"
                    fontSize="14px"
                    textAlign="left"
                    fontWeight="500"
                    colorScheme="red"
                    size="lg"
                    color="rgba(94, 98, 120, 1)"
                    borderRadius="10px"
                    mr="15px"
                    w="100px"
                  ></Checkbox>
                  <Checkbox
                    fontFamily="'Roboto', sans-serif"
                    fontSize="14px"
                    textAlign="left"
                    fontWeight="500"
                    size="lg"
                    color="rgba(94, 98, 120, 1)"
                    borderRadius="10px"
                    mx="15px"
                    colorScheme="red"
                    defaultIsChecked
                    w="100px"
                  ></Checkbox>
                </FormControl>
              </Box>
            </Box>
            <Divider my="12px" />
            <Stack
              w="full"
              alignItems="center"
              flexDir="row"
              justifyContent="flex-end"
              pb="15px"
            >
              <Button
                isDisabled
                fontFamily="'Roboto', sans-serif"
                fontSize="15px"
                textAlign="left"
                fontWeight="500"
              >
                Discard
              </Button>
              <Button
                ml="15px"
                colorScheme="red"
                height="42px"
                fontFamily="'Roboto', sans-serif"
                fontSize="15px"
                textAlign="left"
                fontWeight="500"
              >
                Save Changes
              </Button>
            </Stack>
          </Stack>
        </VStack>
    )
};

export default Notifications;