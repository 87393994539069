import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  Image,
  Input,
  Link,
  Progress,
  Stack,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import TopBar from "../../TopBar";
import { Icon, Img } from "../../../../contents/content";
import Banner from "./components/Banner";
import BasicInfo from "./components/BasicInfo";
import Notifications from "./components/Notifications";
import DeactivateAccount from "./components/DeactivateAccount";

const MyProfile = () => {

  const toast = useToast();

  const [overviewData, setOverviewData] = useState();
  const [notificationData, setNotificationData] = useState();
  const [billingData, setBillingData] = useState();
  const [activityData, setActivityData] = useState();

  const getProfileOverview = async () => {
    if (localStorage.getItem('user') === undefined || localStorage.getItem('user') === null) {
      return;
    };
    const user = JSON.parse(localStorage.getItem('user'));
    try {
      await fetch(
        `https://corsavailable.herokuapp.com/https://realwealth-api-0787684ad674.herokuapp.com/profile/overview/${user.id}`, {
        method: "GET",
        headers: {
          "auth": "TEST",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,OPTIONS,PATCH,DELETE,POST,PUT",
          "Access-Control-Allow-Headers":
            "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version",
          "Content-Type": "application/json",
          "Origin": "",
          "X-Requested-With": ""
        }
      }
      ).then(async (data) => {
        const json = await data.json();
        if (data.status === 200) {
          const responseDataResult = json["result"];
          if (responseDataResult !== undefined && responseDataResult !== '') {
            setOverviewData(responseDataResult);
          }
        } else {
          const error = json['error'];
          if (error !== undefined) {
            showError('Authentication', error);
          } else {
            showError('Authentication', 'Failed to login. Please try again later');
          };
        };
      });
    } catch (error) {
      console.log(error)
    };
  };

  const getProfileNotifications = async () => {
    if (localStorage.getItem('user') === undefined || localStorage.getItem('user') === null) {
      return;
    };
    const user = JSON.parse(localStorage.getItem('user'));
    try {
      await fetch(
        `https://corsavailable.herokuapp.com/https://realwealth-api-0787684ad674.herokuapp.com/profile/notifications/${user.id}`, {
        method: "GET",
        headers: {
          "auth": "TEST",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,OPTIONS,PATCH,DELETE,POST,PUT",
          "Access-Control-Allow-Headers":
            "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version",
          "Content-Type": "application/json",
          "Origin": "",
          "X-Requested-With": ""
        }
      }
      ).then(async (data) => {
        const json = await data.json();
        if (data.status === 200) {
          const responseDataResult = json["result"];
          if (responseDataResult !== undefined && responseDataResult !== '') {
            setNotificationData(responseDataResult);
          }
        } else {
          const error = json['error'];
          if (error !== undefined) {
            showError('Authentication', error);
          } else {
            showError('Authentication', 'Failed to login. Please try again later');
          };
        };
      });
    } catch (error) {
      console.log(error)
    };
  };

  const getProfileBilling = async () => {
    if (localStorage.getItem('user') === undefined || localStorage.getItem('user') === null) {
      return;
    };
    const user = JSON.parse(localStorage.getItem('user'));
    try {
      await fetch(
        `https://corsavailable.herokuapp.com/https://realwealth-api-0787684ad674.herokuapp.com/profile/billing/${user.id}`, {
        method: "GET",
        headers: {
          "auth": "TEST",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,OPTIONS,PATCH,DELETE,POST,PUT",
          "Access-Control-Allow-Headers":
            "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version",
          "Content-Type": "application/json",
          "Origin": "",
          "X-Requested-With": ""
        }
      }
      ).then(async (data) => {
        const json = await data.json();
        if (data.status === 200) {
          const responseDataResult = json["result"];
          if (responseDataResult !== undefined && responseDataResult !== '') {
            setBillingData(responseDataResult);
          }
        } else {
          const error = json['error'];
          if (error !== undefined) {
            showError('Authentication', error);
          } else {
            showError('Authentication', 'Failed to login. Please try again later');
          };
        };
      });
    } catch (error) {
      console.log(error)
    };
  };

  const getProfileActivity = async () => {
    if (localStorage.getItem('user') === undefined || localStorage.getItem('user') === null) {
      return;
    };
    const user = JSON.parse(localStorage.getItem('user'));
    try {
      await fetch(
        `https://corsavailable.herokuapp.com/https://realwealth-api-0787684ad674.herokuapp.com/profile/activity/${user.id}`, {
        method: "POST",
        headers: {
          "auth": "TEST",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,OPTIONS,PATCH,DELETE,POST,PUT",
          "Access-Control-Allow-Headers":
            "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version",
          "Content-Type": "application/json",
          "Origin": "",
          "X-Requested-With": ""
        }
      }
      ).then(async (data) => {
        const json = await data.json();
        if (data.status === 200) {
          const responseDataResult = json["result"];
          if (responseDataResult !== undefined && responseDataResult !== '') {
            setActivityData(responseDataResult);
          }
        } else {
          const error = json['error'];
          if (error !== undefined) {
            showError('Authentication', error);
          } else {
            showError('Authentication', 'Failed to login. Please try again later');
          };
        };
      });
    } catch (error) {
      console.log(error)
    };
  };

  const showError = (title, description) => {
    toast({
      title: title ?? '',
      description: description ?? '',
      status: 'error',
      duration: 3000,
      isClosable: true,
    })
  };

  const changeProfilePhoto = () => {

  };

  useEffect(() => {
    getProfileOverview();
    getProfileNotifications();
    getProfileBilling();
    getProfileActivity();
  },[]);

  const TopBarData = {
    title: "My Profile",
  };
  return (
    <div>
      <VStack w="full" bg="rgba(249, 250, 251, 1)">
        <TopBar title={TopBarData.title} />
        {/* Banner Box */}
        <Banner props={overviewData} showError={showError} changeProfilePhoto={changeProfilePhoto}/>
        {/* Basic Info */}
        <BasicInfo props={overviewData} showError={showError}/>
        {/* Notifications */}
        <Notifications props={notificationData}/>
        {/* Deactivate Account */}
        <DeactivateAccount props={overviewData}/>
      </VStack>
    </div>
  );
};

export default MyProfile;
