import { Box, Button, Image, Stack, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import TopBar from "../TopBar";
import GreetingBanner from "./components/GreetingBanner";
import { DashboardData, GreetingBannerData, Icon, Img, MockAnnouncements, MockRecentCourse } from "../../../contents/content";
import { FaEllipsis, FaPenToSquare } from "react-icons/fa6";
import ResumeCourse from "./components/ResumeCourse";
import Notifications from "./components/Notifications";
import Announcements from "./components/Announcements";
import QuickNav from "./components/QuickNav";

const Dashboard = () => {

  const [users, setUsers] = useState();
  const [recentCourse, setRecentCourse] = useState(MockRecentCourse);
  const [notifications, setNotifications] = useState([]);
  const [greetingBanner, setGreetingBanner] = useState({title: GreetingBannerData.title, subtitle: GreetingBannerData.subtitle, buttonNav: GreetingBannerData.buttonNav});
  const [announcements, setAnnouncements] = useState(MockAnnouncements);
  const [userStatus, setUserStatus] = useState();

  const user = JSON.parse(localStorage.getItem('user'));

  const initializePage = () => {
    fetchCurrentLesson();
  };

  const fetchCurrentLesson = async (lessonId) => {
    try {
      await fetch(
        `https://corsavailable.herokuapp.com/https://realwealth-api-0787684ad674.herokuapp.com/profile/status/${user.id}`, {
          method: "GET",
          headers: {
            "auth": "TEST",
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,OPTIONS,PATCH,DELETE,POST,PUT",
            "Access-Control-Allow-Headers":
              "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version",
            "Content-Type": "application/json",
            "Origin": "",
            "X-Requested-With": ""
          }
        }).then(async (data) => {
            const json = await data.json();
            if (data.status === 200) {
                const responseDataResult = json["result"];
                if (responseDataResult !== undefined && responseDataResult !== '') {
                  fetchLessonVideoData(responseDataResult.current_lesson ?? '', responseDataResult);
                } else {
                    console.log('Failed to store lessons');
                }
            } else {
                const error = json['error'];
                if (error !== undefined) {
                    console.log('Failed to find lessons');
                } else {
                    console.log('Failed to find lessons');
                };
            };
        });
    } catch (error) {
        console.log(error);
    };
  };

  const fetchLessonVideoData = async (lessonId, userData) => {
    try {
      await fetch(
        `https://corsavailable.herokuapp.com/https://realwealth-api-0787684ad674.herokuapp.com/education/lesson/${lessonId}`, {
          method: "GET",
          headers: {
            "auth": "TEST",
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,OPTIONS,PATCH,DELETE,POST,PUT",
            "Access-Control-Allow-Headers":
              "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version",
            "Content-Type": "application/json",
            "Origin": "",
            "X-Requested-With": ""
          }
        }).then(async (data) => {
            const json = await data.json();
            if (data.status === 200) {
                const responseDataResult = json["result"];
                if (responseDataResult !== undefined && responseDataResult !== '') {
                  setUserStatus(userData);
                  setRecentCourse(responseDataResult);
                } else {
                    console.log('Failed to store lessons');
                    setRecentCourse();
                }
            } else {
                const error = json['error'];
                if (error !== undefined) {
                    console.log('Failed to find lessons');
                    setRecentCourse();
                } else {
                    console.log('Failed to find lessons');
                    setRecentCourse();
                };
            };
        });
    } catch (error) {
        console.log(error);
    };
  };

  useEffect(() => {
    initializePage();
  },[]);
  
  return (
    <VStack
      alignItems="flex-start"
      justifyContent="flex-start"
      bg="rgba(249, 250, 251, 1)"
    >
      {/* Top Bar */}
      <TopBar title={"Dashboard"} subtitle={"Welcome to your Real Wealth dashboard"} />
      {/* Dashboard Left  Bar */}
      <Stack
        w="100%"
        flexDir="row"
        alignItems="flex-start"
        paddingX="60px"
        py="10px"
        overflow="hidden"
      >
        <Box w="380px">
          {/* mentor */}
          <ResumeCourse course={recentCourse} userStatus={userStatus}/>
          {/* Notifications */}
          <Notifications/>
        </Box>
        <Box w="100%" px="25px">
          {/* Greeting Banner */}
          <GreetingBanner props={greetingBanner}/>
          {/* Announcements */}
          <Announcements announcements={announcements}/>
        </Box>
      </Stack>
      <Stack w="full" px="30px" py="0px" flexDir="row">
        {/* Quick Nav */}
        <QuickNav/>
      </Stack>
    </VStack>
  );
};

export default Dashboard;
