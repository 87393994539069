import {
    Box,
    Button,
    Divider,
    FormControl,
    FormLabel,
    HStack,
    Image,
    Input,
    Menu,
    MenuButton,
    Stack,
    Text,
    Textarea,
    VStack,
  } from "@chakra-ui/react";
  import { useToast } from "@chakra-ui/react";
  import React, { useEffect, useState } from "react";
  import { Icon, Img } from "../../../../../contents/content";

const Banner = ({ props }) => {

    useEffect(() => {

    },[]);

    return(
        <>
        {/* Top Bar */}
        <Stack position="relative" w="full" mb={'20px'}>
              <Image
                src={props && props.image != '' ? props.image : Img.educationBg2}
                alt="educationBg2"
                borderRadius="20px"
                fit={'cover'}
                w="full"
                h="250px"
              />
              <Stack
                w="full"
                h="full"
                position="absolute"
                top="0"
                left="0"
                alignItems="flex-start"
                justifyContent="flex-end"
                p="30px"
              >
                <Text
                  color="rgba(255, 255, 255, 1)"
                  fontFamily="'Poppins', sans-serif"
                  fontSize="28px"
                  fontWeight="600"
                  textAlign="left"
                >
                  {props && props.title !== '' ? props.title :' Contribute to our Amazing Community'}
                </Text>
                <Text
                  color="rgba(255, 255, 255, 1)"
                  fontFamily="'Poppins', sans-serif"
                  fontSize="15px"
                  fontWeight="500"
                >
                    {props && props.description !== '' ? props.description : "Drop a post or an update, I'm sure the community would love to see your progress!"}
                  
                </Text>
              </Stack>
            </Stack>
        </>
    )
};

export default Banner;